import React from 'react'

import blockchain2 from './img/blockchain2.png'
const Contractsearch=()=> {

  return (
    <div>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec ">
            <div className="row">
              <div className="col-lg-6 Verify-col rbc center"> 
                <img src={blockchain2} className="blockchain" alt="" />
              </div>
              <div className="col-lg-6 Verify-col2">
                <h2 className="w faq-title afacad mb-2">Similar Contracts Search</h2>
                <form>
                  <label className="form-label1 w afacad">Contract Address *</label><br />
                  <input placeholder="Ox..." type="text" className="form-control1" /><br />
                  <label className="form-label1 w afacad">Similarity</label><br />
                  <select name="cars" id="cars" className="form-control1">
                    <option value="volvo">Exact</option>
                    <option value="saab">Similar</option>
                  </select><br />
                  <label className="form-label1 w afacad">Chains</label><br />
                  <select name="cars" id="cars" className="form-control1">
  <option value="" disabled selected>
  Select Below

  </option>
  <option value={1}>Ethereum</option>
  <option value={2}>Ethereum Goerli</option>
  <option value={3}>Ethereum Sepolia</option>
  <option value={4}>BSC</option>
  <option value={5}>BSC Testnet</option>
  <option value={6}>Polygon POS</option>
</select>

                  <input defaultValue="Search" type="submit" className="form-submit afacad b" />
                  <input defaultValue="Reset" type="reset" className="form-reset afacad g" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   
  )
}

export default Contractsearch
