
import React, { useState, useEffect } from 'react';
import Green from '../../../Img/Green.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { base_url } from '../../../config/Api';

const formatIndianNumber = (number) => {
 
  if (number >= 1000000000) {
    const billionValue = number / 1000000000;
    
    return `${billionValue.toFixed(2)} billion `;
  }
  
  else if (number >= 10000000) {
    const croreValue = number / 10000000;
    
    return `${croreValue.toFixed(2)} Cr`;
  }
  
  else if (number >= 100000) {
    const lakhValue = number / 100000;
    
    return `${lakhValue.toFixed(2)} Lakh`;
  }
  
  else {
    return new Intl.NumberFormat('en-IN').format(number);
  }
};

const Totaltransactions = () => {
  const [totalTransactions, setTotalTransaction] = useState({});
  const [totalAmount, settotalAmount] = useState({});
  const [totalBlocks, settotalBlocks] = useState({});
  const [loading, setLoading] = useState(true);

  
  const fetchData = async () => {
    try {
      
      const response = await fetch(`${base_url}/api/transaction`);
      const data = await response.json();

      
      setTotalTransaction({
        amount: data.totalTransactions,
      });

      settotalAmount({
        amount: data.totalAmount,
      });

      settotalBlocks({
        amount: data.totalBlocks,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="section1 my-5">
      <div className="container">
        <div className="row section1-box">
          <div className="col-lg-4 col-md-4 col_1">
            <div className="d-flex icon-box">

              <div >
                <div className="icon-box-icon01">
                  <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                </div>
              </div>
              <div className="icon-box-text">
                <p className="icon-box-title bl inter">Total txn</p>
                <p className="icon-box-sub w afacad">
                  {loading ? <Skeleton  baseColor="#202020" highlightColor="#444"/> : formatIndianNumber(totalTransactions.amount)}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4  col_1">
            <div className="d-flex icon-box">

              <div >
                <div className="icon-box-icon01">
                  <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                </div>
              </div>
              <div className="icon-box-text">
                <p className="icon-box-title bl inter">Total txn value </p>
                <p className="icon-box-sub w afacad">
                  {loading ? <Skeleton  baseColor="#202020" highlightColor="#444"/> : formatIndianNumber(totalAmount.amount && totalAmount.amount.$numberDecimal?.slice(0, 15))}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-chart">
            <div className="d-flex icon-box">

              <div >
                <div className="icon-box-icon01">
                  <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                </div>
              </div>
              <div className="icon-box-text">
                <p className="icon-box-title bl inter">Total Block</p>
                <p className="icon-box-sub w afacad">
                  {loading ? <Skeleton  baseColor="#202020" highlightColor="#444"/> : formatIndianNumber(totalBlocks.amount)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Totaltransactions;
