import React from "react";
const Policy = () => {
  return (
    <>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec">
            <h4 className="chart-title w afacad">Please read this Privacy Policy Carefully</h4>
            <p className="icon-box-title bl inter">GLC (referred to as the "Company", "GLCScan", “we”, “our” or
              “us”) is dedicated to safeguarding your Personal Data and prioritizes your privacy.</p>
            <h4 className="chart-title w afacad">1. TYPES OF DATA WE COLLECT</h4>
            <p className="icon-box-title bl inter">We gather Personal Data directly from you or third parties, as required for the requested services. This
              includes:
            </p>
            <p className="icon-box-title bl inter">(a) Personal details (e.g., name, gender, date of birth)
            </p>
            <p className="icon-box-title bl inter">(b) Contact particulars (e.g., address, phone number, email)</p>
            <p className="icon-box-title bl inter">(c) Technical specifics (e.g., IP address, login credentials)</p>
            <p className="icon-box-title bl inter">Analytical insights (e.g., website interactions)
            </p>
            {/* <p className="icon-box-title bl inter">This Privacy Policy encompasses the information we collect about you when you use our products or services or engage with GLCScan. It applies unless a distinct privacy policy is presented. This policy also outlines your options regarding how we utilize information about you.</p>
            <p className="icon-box-title bl inter">Your choices encompass your ability to object to specific uses of information about you and to access and update certain details concerning you. If you do not consent to the terms of this Policy, kindly refrain from using the Site or any of our Services. The prevailing version of this Privacy Policy will be applicable each time you utilize any Site or Services.</p> */}
            <h4 className="chart-title w afacad">2. HOW DO WE COLLECT PERSONAL DATA?
            </h4>
            <p className="icon-box-title bl inter">This Privacy Policy pertains to any Personal Data provided to us:
            </p>
            <p className="icon-box-title bl inter">(a)Interaction with our products and services at GLCScan;
            </p>
            <p className="icon-box-title bl inter">(b) Account creation at GLCScan;</p>
            <p className="icon-box-title bl inter">(c) Through contractual agreements or arrangements with GLCScan.
            </p>
            <p className="icon-box-title bl inter">Other methods of collecting Personal Data may include (but are not limited to):</p>
            <p className="icon-box-title bl inter">(a) Communication via telephone, letter, fax, and email at GLCScan;</p>
            <p className="icon-box-title bl inter">(b) Website visits at GLCScan;</p>
            <p className="icon-box-title bl inter">(c)  In-person interactions at GLCScan</p>
            <p className="icon-box-title bl inter">(d)Direct contact initiated by GLCScan;</p>
            <p className="icon-box-title bl inter">(e) Gathering information from third parties and other channels, including our support helpdesk at GLCScan</p>
            <h4 className="chart-title w afacad">3. HOW WE COLLECT YOUR PERSONAL DATA ON OUR WEBSITE ?</h4>
            <p className="icon-box-title bl inter">At GLCScan, we collect your Personal Data on our website through the following methods:</p>
            <p className="icon-box-title bl inter">
              <b>IP Address</b>
              We use your IP address to diagnose server issues and manage our website.
            </p>
            <p className="icon-box-title bl inter">
              <b>Cookies</b>
              Cookies are used on some of our pages to store preferences and session information, providing
              a more personalized user experience. You can adjust your browser settings to manage cookie
              preferences
            </p>
            {/* <p className="icon-box-title bl inter">The information that we collect is then utilized to ensure a more personalized service level for our users at GLCScan. You can adjust settings on your browser so that you will be notified when you receive a cookie. Please refer to your browser documentation to check if cookies have been enabled on your computer or to request not to receive cookies.</p>
            <p className="icon-box-title bl inter">As cookies allow you to take advantage of some of the Website’s essential features, we recommend that you accept cookies. For instance, if you block or otherwise reject our cookies, you will not be able to use any products or services on the website at GLCScan that may require you to log-in (token holdings store cookies for favorite).</p>
            <p className="icon-box-title bl inter">It is important that you prevent unauthorized access to your password and your computer. You should always log out after using a shared computer. Information collected from cookies is used by us at GLCScan to evaluate the effectiveness of our site, analyze trends, and manage the platform. The information collected from cookies allows us to determine such things as which parts of our site are most visited and difficulties our visitors may experience in accessing our site.</p>
            <p className="icon-box-title bl inter">With this knowledge, we can enhance the quality of your experience on the platform at GLCScan by recognizing and delivering more of the most desired features and information, as well as by resolving access difficulties. We also use cookies and/or a technology known as web bugs or clear gifs, which are typically stored in emails to help us confirm your receipt of, and response to our emails, and to provide you with a more personalized experience when using our site. Your continued use of this site, as well as any subsequent usage, will be interpreted as your consent to cookies being stored on your device.</p> */}
            <p className="icon-box-title bl inter">
              <b>User Feedback Form</b>
              Our feedback form requires contact information (e.g., name, email) to respond
              to comments and provide company information.
            </p>
            <p className="icon-box-title bl inter">
              <b>General Site Tracking:</b>
              We employ third-party service providers to analyze site usage, track
              navigation, and gather transaction information. This data helps us understand visitor interests and
              improve our services
            </p>
            {/* <p className="icon-box-title bl inter">The information and analysis provided by our service provider(s) at GLCScan will be used to assist us in better understanding our visitors' interests in our site and how to better serve those interests. The information collected by our service provider(s) may be linked to and combined with information that we collect about you while you are using the platform. Our service provider(s) is/are contractually restricted from using information they receive from our Site other than to assist us.</p> */}
            <p className="icon-box-title bl inter">
              <b>(e) Web Server Site Visits Logging:
              </b>
            </p>
            <p className="icon-box-title bl inter">(i)We track incoming IP addresses to manage request rates and prevent attacks, releasing them
              after a short period.
            </p>
            <p className="icon-box-title bl inter">(ii) 'x-forwarded-for' IPs are not stored by default but may be temporarily logged for specific
              reasons.</p>
            <p className="icon-box-title bl inter">(iii)Raw web server logs are retained for a minimum of 5 days for routine maintenance.</p>
            <p className="icon-box-title bl inter">(iv)Your continued use of our website implies consent to the storage of cookies on your device. We prioritize the
              security of your data and recommend safeguarding your password and logging out from shared devices.</p>
            <h4 className="chart-title w afacad">4.USE OF YOUR PERSONAL DATA?</h4>
            <p className="icon-box-title bl inter">We may utilize your Personal Data for the following purposes:</p>
            <p className="icon-box-title bl inter">(a) Providing and enhancing services at GLCScan.
            </p>





            <p className="icon-box-title bl inter">(b) Ensuring safety and well-being.</p>
            <p className="icon-box-title bl inter">(c)Investigating and addressing inquiries and claims.</p>
            <p className="icon-box-title bl inter">(d) Business development, including analysis and marketing.</p>
            <p className="icon-box-title bl inter">(e) Compliance with legal and regulatory requirements.</p>
            <p className="icon-box-title bl inter">(f) Communication of offers, products, and services.</p>
            {/* <p className="icon-box-title bl inter">(g) to communicate offers, product, services, and information on products and activities at GLCScan;</p>
            <p className="icon-box-title bl inter">(h) marketing/cross-marketing and communicating with you in relation to products and services offered by us and our service partners as well as our appointed agents at GLCScan; and/or</p>
            <p className="icon-box-title bl inter">(i) for all other purposes ancillary to any of the purposes stated above ("Ancillary Purposes") at GLCScan.</p> */}

            <h4 className="chart-title w afacad">5. ACCESSING/CORRECTING/UPDATING YOUR PERSONAL DATA</h4>
            <p className="icon-box-title bl inter">You can access and update your personal data by logging into your online account at GLCScan and you have to
              pay a fee for that</p>
            <h4 className="chart-title w afacad">6. WITHDRAWING CONSENT
            </h4>
            <p className="icon-box-title bl inter">Processing of Personal Data for Core Purposes is obligatory. Consent withdrawal may impact services. You can
              stop promotional activities by unsubscribing, editing account settings, or contacting us</p>
            {/* <p className="icon-box-title bl inter">If we do not have your consent to process your Personal Data for the Ancillary Purposes, we will not be able to keep you updated about our future, new, and/or enhanced services and products at GLCScan. Nevertheless, you may stop receiving promotional activities by:</p>
            <p className="icon-box-title bl inter">(a) unsubscribing from the mailing list at GLCScan;</p>
            <p className="icon-box-title bl inter">(b) editing the relevant account settings to unsubscribe at GLCScan; or</p>
            <p className="icon-box-title bl inter">(c) sending a request via Contact Us at GLCScan.</p> */}
            <h4 className="chart-title w afacad">7. DISCLOSURE OF YOUR PERSONAL DATA?</h4>
            <p className="icon-box-title bl inter">We don't trade/sell your Personal Data. It may be disclosed to authorized third parties for specific purposes,
              Legal obligations may necessitate data processing for purposes beyond those disclosed herein.</p>
            {/* <p className="icon-box-title bl inter">In addition to the above, your Personal Data may also be disclosed or transferred to any of the Company’s actual and potential assignee, transferee, or acquirer (including our affiliates and subsidiaries) or our business, assets, or group companies, or in connection with any corporate restructuring or exercise, including our restructuring to transfer the business, assets, and/or liabilities at GLCScan.</p>
            <p className="icon-box-title bl inter">We shall take practical steps to ensure that their employees, officers, agents, consultants, contractors, and such other third parties mentioned above who are involved in the collection, use, and disclosure of your Personal Data will observe and adhere to the terms of this Privacy Statement at GLCScan.</p>
            <p className="icon-box-title bl inter">We are subject to various legal and regulatory obligations imposed by the laws and supervisory authorities of various jurisdictions, e.g., anti-money laundering laws, anti-terrorism financing, financial services laws, corporation laws, and privacy laws. These obligations may require us to process certain data for payment processing, compliance with court orders, or other purposes not disclosed herein at GLCScan.</p> */}
            <h4 className="chart-title w afacad"> 8. DATA RETENTION
            </h4>
            <p className="icon-box-title bl inter">We store data with a global hosting provider and ensure its permanent deletion when no longer needed for the 
Purpose. Inactive data is disposed of after 24 months at GLCScan..</p>
            <h4 className="chart-title w afacad">9. LINKS TO THIRD-PARTY WEBSITES
</h4>
            <p className="icon-box-title bl inter">We may link to Third Party Sites, but this Privacy Notice does not apply to them. Operators of these sites may 
collect your personal information when accessed through provided links at GLCScan</p>
            <h4 className="chart-title w afacad">10. ADDITIONAL INFORMATION</h4>
            <p className="icon-box-title bl inter">This Privacy Statement may be amended in accordance with applicable laws. For inquiries, complaints, or to 
access, update, or amend your Personal Data, contact us via our feedback form at Contact Us at GLCScan.
</p>
            {/* <p className="icon-box-title bl inter">For further inquiries or complaints in relation to our handling of your Personal Data or our Privacy Policy or wish to access, update, or amend your Personal Data as mentioned above, please contact us via our feedback form at Contact Us at GLCScan.</p> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default Policy;