// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    try {
      const auth = localStorage.getItem('user');
      
      if (auth) {
        setUser(JSON.parse(auth));
      }
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }, []);

  const login = (userData) => {
    // console.log('Login', userData)
    localStorage.setItem('user', JSON.stringify(userData));
    // console.log(userData);
    setUser(userData);
  };

  const logout = () => {    
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
