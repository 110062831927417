import React from "react";
import mask from './IMG/mask.png'
import mask12 from './IMG/mask12.png'
import mask13 from './IMG/mask13.png'
import mask22 from './IMG/mask22.png'
import mask15 from './IMG/mask15.png'
import mask16 from './IMG/mask16.png'
const RbcGuide=()=>{
    return(
        <>
      
  
  <div className="container center margin-top">
    <div className="smart">
      <h2 className="w banner-title afacad">Connecting MetaMask to GLC Smart Chain</h2>
      <h4 className="chart-title w afacad">Installing and Setting up MetaMask</h4>
      <p className="text- w inter mt-2">MetaMask can be downloaded on Chrome and Firefox, or on iOS and Android for mobile users. For this tutorial, we’ll use the Firefox version, but the instructions are similar for all platforms.</p>
      <p className="text- w inter mt-2">Firstly, head over to the MetaMask Download page. Select your platform and follow the installation steps. Easy!</p>

      <p className="text- w inter mt-2">Next, follow the setup instructions provided by the app. Click Create a Wallet. Write down the backup seed phrase somewhere secret (preferably not on an Internet-connected device). This phrase is crucial for recovering your funds if your device is damaged or lost. Confirm that you’ve written it down on the next page.</p>
      <p className="text- w inter mt-2">And that’s it! Your wallet is now ready to send and receive funds.</p>
      <img className="funds-img my-3" src={mask} style={{width:"50%", height:"50%"}} alt="" />
      <h2 className="w banner-title afacad">Configuring the wallet</h2>
      <p className="text- w inter mt-2">You might notice right away that we're still dealing with an Ethereum wallet. At best, this won't work with GLC Smart Chain (GLC) DApps. At worst, you could lose funds by sending them to addresses you can't actually use.</p>
      <p className="text- w inter mt-2">Let's fix that. We need to access the Settings to configure the wallet to connect to GLC Smart Chain nodes.</p>
      <img className="funds-img my-3" src={mask12} style={{width:"50%", height:"50%"}} alt="" />
      <p className="text- w inter mt-2">On the Settings page, we want to locate the Networks menu.</p>
      <img className="funds-img my-3" src={mask13} style={{width:"50%", height:"50%"}}  alt="" />
      <p className="text- w inter mt-2">To configure MetaMask for GLC Smart Chain, click "Add Network" in the top-right corner, as it isn't included by default. You have the option to connect to either the testnet or the mainnet. Below are the details you need to enter for each.</p>
      <h4 className="chart-title w afacad">Mainnet (This is the one you are probably looking for)</h4>
      <p className="text- w inter mt-2"><b>Network Name:-  Green Ledger Coin  </b></p>
      <p className="text- w inter mt-2"><b>New RPC URL:-  https://glc-dataseed.glscan.io/</b></p>
      <p className="text- w inter mt-2"><b>ChainID :-  10222</b></p>
      <p className="text- w inter mt-2"><b>Symbol :-  GLC</b></p>
      <p className="text- w inter mt-2"><b>Block Explorer URL :-  https://glscan.io/</b></p>
      <img className="funds-img my-3" src={mask22} style={{width:"50%", height:"50%"}} alt="" />
      <h2 className="w banner-title afacad">Making transactions (on the testnet)</h2>
      <img className="funds-img my-3" src={mask15} style={{width:"50%", height:"50%"}} alt="" />
      <p className="text- w inter mt-2">Peggy coins might be of interest if you’re testing out an application that supports BEP-20 tokens. They’re simply tokens issued on GLC Smart Chain that are “pegged” to assets on other chains (like BTC, XRP, USDT, etc.), meaning that they trade at the same price. </p>
      <p className="text- w inter mt-2">For now, let's focus on GLC. Click on the "Give me GLC" dropdown and select the amount you wish to receive. You might need to wait a couple of minutes, but the funds will appear in your testnet wallet shortly.</p>
      <img className="funds-img my-3" src={mask16} style={{width:"50%", height:"50%"}}alt="" />
      <p className="text- w inter mt-2">We've initiated a transaction of 1 GLC. Leave the fees unchanged and click "Next." You'll then have one last chance to review the transaction. If everything looks correct, click "Confirm." That's it! You'll receive a notification once your transaction has been processed.
      </p>
    </div>
  </div>

        </>
    )
}
export default RbcGuide;
