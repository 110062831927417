
import React, { useState, useEffect } from "react";
import { defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { base_url } from "../config/Api";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";

defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.width = 500;

const LineCharts = () => {
  let { state } = useLocation();
  console.log("state", state);

  const [timeInterval, setTimeInterval] = useState("all");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [revenueData, setRevenueData] = useState({
    chartLabels: [],
    chartValues: [],
  });

  console.log("revenueData", revenueData);
  useEffect(() => {
    const fetchData = async () => {
      console.log('on change chartLabels');
      try {
        const response = await fetch(`${base_url}/api/transaction/chart?timerange=${timeInterval}`);
        const data = await response.json();
        if (typeof data === "object" && data !== null) {
          for (const key in data) {
            if (Array.isArray(data[key])) {
              data[key] = data[key].reverse();
            }
          }
          if (state && Array.isArray(state) && state.length > 1) {
            setRevenueData({
              chartLabels: data[state[0]],
              chartValues: data[state[1]],
            });
          } else {
            console.error("State object is not in the expected format:", state);
          }
        } else {
          console.error("Fetched data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [timeInterval, state]);

  const downloadChart = async (format) => {
    const chartContainer = document.getElementById("chart-container");

    if (!chartContainer) {
      console.error("Chart container not found.");
      return;
    }

    html2canvas(chartContainer)
      .then((canvas) => {
        if (format === "svg") {
          const img = canvas.toDataURL("image/png");
          const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">
              <image href="${img}" height="100%" width="100%"/>
            </svg>`;
          const blob = new Blob([svg], { type: "image/svg+xml" });
          saveAs(blob, `GLC_Chart.${format}`);
        } else if (format === "pdf") {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [canvas.width, canvas.height]
          });
          pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
          pdf.save("GLC_Chart.pdf");
        } else {
          const dataURL = canvas.toDataURL(`image/${format}`);
          const link = document.createElement("a");
          link.href = dataURL;
          link.download = `GLC_Chart.${format}`;
          link.click();
        }
      })
      .catch((error) => {
        console.error("Error downloading chart:", error);
      });
  };

  const printChart = () => {
    window.print();
  };

  const toggleFullScreen = () => {
    const chartContainer = document.getElementById("chart-container");
    if (!document.fullscreenElement) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) { // Firefox
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) { // Chrome, Safari and Opera
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) { // IE/Edge
        chartContainer.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="table-sec" style={{ marginTop: "15px ", height: "600px" }}>
          {/* <h4 className="chart-title w afacad">GLC Daily Price (USD) Chart</h4> */}
          <h4 className="chart-title w afacad">GLC {state && state[1] ? state[1] : 'Loading...'} (USD) Chart</h4>
          {/* <p className="icon-box-title bl inter">Source: GLCscan.com</p> */}
          <p className="icon-box-title bl inter">Click and drag in the plot area to zoom in</p>
          <div className="chart-icon icon-chart no-print">
            <div className="dropdown dropdown-menu-end">
              <button type="button" className=" dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-solid fa-sliders w"></i>
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item inter"  onClick={toggleFullScreen}>View in full screen</a></li>
                <li><a className="dropdown-item inter"  onClick={printChart}>Print chart</a></li>
                <li>
                  <a className="dropdown-item inter"  onClick={() => downloadChart("png")}>
                    Download PNG image
                  </a>
                </li>
                <li>
                  <a className="dropdown-item inter"  onClick={() => downloadChart("jpeg")}>
                    Download JPEG image
                  </a>
                </li>
                <li>
                  <a className="dropdown-item inter"  onClick={() => downloadChart("pdf")}>
                    Download PDF document
                  </a>
                </li>
                <li>
                  <a className="dropdown-item inter"  onClick={() => downloadChart("svg")}>
                    Download SVG vector image
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hy" id="chart-container">
            <div className="dse no-print" style={{ display: "flex" }}>
              <button className="gh" onMouseUp={() => setTimeInterval("1month")}>1M</button>
              <button className="gh" onMouseUp={() => setTimeInterval("6months")}>6M</button>
              <button className="gh" onMouseUp={() => setTimeInterval("1year")}>1Y</button>
              <button className="gh" onMouseUp={() => setTimeInterval("all")}>All</button>
            </div>
            <Line
              data={{
                labels: revenueData.chartLabels.map((date) => new Date(date).toLocaleDateString('en-Us', { month: 'short', day: 'numeric' })),
                datasets: [
                  {
                    data: revenueData.chartValues,
                    fill: true,
                    backgroundColor: "#005a00",
                    borderColor: "#d0f459",
                    borderWidth: 2,
                    pointBackgroundColor: "#d0f459",
                    pointRadius: 3.5,
                    pointHoverRadius: 4,
                  },
                ],
              }}
              options={{
                elements: {},
                plugins: { legend: { display: false } },
                scales: {
                  x: { grid: { display: true, color: "rgba(0, 0, 0, 0.1)" }, ticks: { color: "#d0f459" } },
                  y: { grid: { display: true, color: "#d0f459" }, ticks: { color: "#d0f459" } },
                },
                maintainAspectRatio: false,
                responsive: true,
                legend: { display: false },
              }}
            />
          </div>
        </div>
        <div className="table-sec mt-4">
          <div className="row pro_01">
            <div className="col-lg-6 col-md-6">
              <div className="d-flex icon-box">
                <div className="icon-box-icon01">
                  <img className="box-icon" src="./image/logo.png" alt="" />
                </div>
                <div className="icon-box-text">
                  <p className="icon-box-title bl inter">CURRENT GLC PRICE <span className="g">(0.47% Minable)</span></p>
                  <p className="icon-box-sub w afacad">$306.22<span className="bl">( @ 0.00726345549173161 BTC/GLC)</span></p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h4 className="chart-title w afacad">About</h4>
              <p className="icon-box-title bl inter">The GLC Daily Price (USD) chart shows the daily historical price for GLC in USD.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LineCharts;

