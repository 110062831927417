import React from 'react'
import Banners from './Homepart/banners'
import Marked from './Homepart/Marked';
import Transactions from './Homepart/Transactions';
import Table from './Homepart/Table';
export default function Home() {
  return (
    <div>
      <Banners/>
      <Marked/>
      <Transactions/>
      <Table/>

    </div>
  )
}
