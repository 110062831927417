
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Green from '../Img/Green.png'
import { base_url } from '../config/Api';
import { toast } from 'react-toastify';
const Blockhash = () => {
    const { blockNumber } = useParams();
    const [blockDetails, setBlockDetails] = useState(null);
    useEffect(() => {
        localStorage.setItem('blockNumber', blockNumber);
        console.log(blockNumber);
    }, [blockNumber]);
    
    const copyText = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.dismiss()
        toast.success("Text copied to clipboard!");
    };
    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(` ${base_url}/api/blocks/blocknum/${blockNumber}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch block data. Status: ${response.status}`);
                }

                const result = await response.json();
                console.log(result.detailsOfSingleBlock);
                setBlockDetails(result.detailsOfSingleBlock)

            } catch (error) {
                console.error('Error fetching block data:', error);
            }
        };

        fetchBlockData();
    }, [blockNumber]);

    return (
        <>


            
            {blockDetails && (
                <div>
                    <div className="section2 my-5">
                        <div className="container">
                            <div className="table-sec">
                                <div className="row">
                                  
                                    <div className="col-lg-6 verify-col01">
                                        <div className="d-flex icon-box icon-box1">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div className="icon-box-text">
                                                <p className="icon-box-title bl inter">Block Height</p>
                                                <p className="icon-box-sub1 w afacad">{blockDetails.number}</p>
                                            </div>
                                        </div>

                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter"> Validated By</p>
                                                <p class="icon-box-sub1 w afacad"> <span id="sample" class="g">Validator: Pexmons</span>

                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyText('Validator: Pexmons')}
                                                    >
                                                        <i className="fa-regular fa-copy g"></i>
                                                    </button>
                                                    in 3 secs</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Difficulty</p>
                                                <p class="icon-box-sub1 w afacad"> {blockDetails.difficulty}</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Timestamp</p>
                                                <p class="icon-box-sub1 w afacad">{blockDetails.timestamp}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 Verify-col2">
                                        <div class="d-flex icon-box icon-box1">
                                            <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Block Reward</p>
                                                <p class="icon-box-sub1 w afacad">0.043076036028567832 BNB</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                            <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Size</p>
                                                <p class="icon-box-sub1 w afacad"> {blockDetails.size}                     </p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                            <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter"> Total Difficulty</p>
                                                <p class="icon-box-sub1 w afacad">  {blockDetails.totalDifficulty}</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box2">
                                            <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Transactions</p>
                                                {/* <p class="icon-box-sub1 w afacad">{transaction.blockCount}</p> */}
                                                <p class="icon-box-sub1 w afacad "><NavLink className="w" to="/BlockTransaction">{blockDetails.blockCount}</NavLink></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="section2 my-5">
                        <div class="container">
                            <div class="table-sec ">
                                <div class="row">
                                    <div class="col-lg-6 verify-col01">
                                        <div class="d-flex icon-box icon-box1">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Gas Used</p>
                                                <p class="icon-box-sub1 w afacad">{blockDetails.gasUsed}</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Gas Limit</p>
                                                <p class="icon-box-sub1 w afacad"> {blockDetails.gasLimit}</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Burnt Fees</p>
                                                <p class="icon-box-sub1 w afacad">  0.004038378377678234 BN</p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box2 fefwegerw">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text knvgjren">
                                                <p class="icon-box-title bl inter">Hash</p>
                                                <p class="icon-box-sub1 w afacad"><span id="sample">{blockDetails.hash?.slice(0, 10)}</span>
                                                    
                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyText(blockDetails.hash)}
                                                    >
                                                        <i className="fa-regular fa-copy g"></i>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 Verify-col2">

                                        <div class="d-flex icon-box icon-box1">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter"> Parent Hash</p>
                                                <p class="icon-box-sub1 w afacad"><span id="sample">{blockDetails.parentHash?.slice(0, 10)} </span>
                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyText(blockDetails.parentHash)}
                                                    >
                                                        <i className="fa-regular fa-copy g"></i>
                                                    </button></p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box1 icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Nonce</p>
                                                <p class="icon-box-sub1 w afacad"><span id="sample"> {blockDetails.nonce}                            </span>
                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyText(blockDetails.nonce)}
                                                    >
                                                        <i className="fa-regular fa-copy g"></i>
                                                    </button></p>
                                            </div>
                                        </div>
                                        <div class="d-flex icon-box icon-box2">
                                        <div >
                                                <div className="icon-box-icon01">
                                                    <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                                                </div>
                                            </div>
                                            <div class="icon-box-text">
                                                <p class="icon-box-title bl inter">Sha3Uncles</p>
                                                <p class="icon-box-sub1 w afacad"><span id="sample"> {blockDetails.sha3Uncles?.slice(0, 10)}</span>
                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyText(blockDetails.sha3Uncles)}
                                                    >
                                                        <i className="fa-regular fa-copy g"></i>
                                                    </button></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section2 my-5">
                        <div class="container">
                            <div class="table-sec " >
                                <h4 class="chart-title w afacad">Extra Data</h4>
                                <textarea
                                    className="form-control text-muted text-monospace p-3"
                                    style={{ height: "200px" }}
                                    rows="7"
                                    id="extraData"
                                    value={`Hex: ${blockDetails.logsBloom}`}
                                    readOnly
                                />

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}
export default Blockhash;