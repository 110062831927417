
import React, { useState } from 'react';
import etherscan from '../Img/etherscan.png'
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Blockscan');

  // Define icons and list items
  const icons = { /* your icon paths */ };
  const listItems = ["Etherscan", "PolygonScan", "FtmScan", "Arbiscan", "Moonriver"];

  // Function to toggle dropdown
  const toggleDropdown = () => {
    console.log("Dropdown toggled");
    // console.log(listItems) // Add this line for debugging
    setIsOpen(!isOpen);
  };

  // Function to handle item selection
  const handleItemClick = (item) => {
    setSelectedItem(item);
    // console.log(item)
    toggleDropdown(); // Close dropdown after selection
  };

  return (
    <div className="dropdown-container">
      <button className="dropdown-button main-button" onClick={toggleDropdown}>
        <span className="dropdown-title-icon">
          <img src={etherscan}  className="nop" alt="" />
        </span>
        <span className="dropdown-title text-truncate">{selectedItem}</span>
        <span className="dropdown-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path d={isOpen ? "M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" : ""} />
          </svg>
        </span>
      </button>
      {isOpen && (
  <div className="dropdown-list-container">
    <div className="dropdown-list-wrapper">
      <ul className="dropdown-list">
        {listItems.map((item, index) => (
          <li key={index} className="dropdown-list-item">
            <button className="dropdown-button list-button" onClick={() => handleItemClick(item)}>
              <span className="text-truncate">{item}</span>
            </button>
          </li>
        ))}
      </ul>
      <div className="floating-icon" aria-hidden="true"></div>
    </div>
  </div>
)}

    </div>
  );
};

export default Dropdown;
