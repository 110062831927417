import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import { base_url } from '../../../config/Api';
import Web3 from 'web3'; 
const Totalblocks = () => {
  const [blocks, setBlocks] = useState([]);
  const [countBlocks, setCountBlocks] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [cumulativeIndex, setCumulativeIndex] = useState(0);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const web3Instance = new Web3('https://glc-dataseed.glcscan.co.io/'); // Replace with your Ethereum node URL if needed
  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${base_url}/api/blocks?page=${currentPage}&limit=${selectedItemsPerPage}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch blocks. Status: ${response.status}`);
        }

        const result = await response.json();

        if (result && Array.isArray(result.blocks)) {
          setBlocks(result.blocks);
          setCountBlocks(result.countBlocks);
          const newCumulativeIndex = (currentPage - 1) * selectedItemsPerPage;
          setCumulativeIndex(newCumulativeIndex);
        } else {
          console.error('Invalid or missing block data in the API response:', result);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching block data:', error);
      }
    };

    fetchBlocks();
  }, [currentPage, selectedItemsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newCumulativeIndex = (page - 1) * selectedItemsPerPage;
    setCumulativeIndex(newCumulativeIndex);
  };

  const handleItemsPerPageChange = (selectedValue) => {
    setSelectedItemsPerPage(selectedValue);
    setCurrentPage(1);
    setCumulativeIndex(0);
  };

  // const pagination = () => {
  //   const totalPages = Math.ceil(countBlocks / selectedItemsPerPage);
  //   const pages = [];

  //   pages.push(
  //     <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
  //     <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
  //   );

  //   for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
  //     pages.push(
  //       <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }

  //   pages.push(
  //     <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
  //     <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
  //   );

  //   return <Pagination>{pages}</Pagination>;
  // };
  const pagination = () => {
    const totalPages = Math.ceil(countBlocks / selectedItemsPerPage);
    const pages = [];

    pages.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
        pages.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }

    pages.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />,
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return <Pagination>{pages}</Pagination>;
};

  return (
    <>
      <div className="section2">
        <div className="container">
          <div className="table-sec " id='pli'>
            <div className='tab'>
              <h4 className="chart-title w afacad" id='pod'>{`Total Blocks Found ${countBlocks}`}</h4>
              <div>
                <select value={selectedItemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>
            <div className="transactions">
              {loading ? (
                <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444"/>
              ) : (
                <table className="my-3 found-table5">
                  <thead>
                    <tr className="table-row">
                      <th className="tabel-heading w afacad">Sr no</th>
                      <th className="tabel-heading w afacad">blocks</th>
                      <th className="tabel-heading w afacad">Age</th>
                      <th className="tabel-heading w afacad">Validator</th>
                      <th className="tabel-heading w afacad">Txn</th>
                      <th className="tabel-heading w afacad">Gas Used</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blocks.map((block, index) => (
                      <tr className="table-row" key={index}>
                        <td className="tabel-text w inter">{cumulativeIndex + index + 1}</td>
                        <td className="tabel-text w inter"><NavLink style={{color:"white"}} to={`/blockhash/${block.blockNumber}`}>{block.blockNumber}</NavLink></td>
                        <td className="tabel-text w inter">{block.elapsedTime}</td> 
                        <td className="tabel-text w inter">{block.from ?.slice(0, 10)}</td>
                        <td className="tabel-text w inter">{block.blocksDataCount}</td>
                        <td className="tabel-text w inter">{block.gasUsed}</td>
                        {/* <td className="tabel-text w inter">
    {block.gasUsed ? parseFloat(web3Instance.utils.fromWei(block.gasUsed, 'ether')).toFixed(10) : null}
</td> */}

                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {pagination()}
            <button onClick={() => setSidebarVisible(!isSidebarVisible)}>
              Toggle Sidebar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Totalblocks;
