import React from "react";

import Totalblocks  from './Totalblocks/Totalblocks '
const Block=()=>{
    return(
        <>
        
        <Totalblocks/>
        </>
    )
}
export default Block