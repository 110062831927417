import React from 'react'

const APIDocumentation=()=> {
  return (
    <div>
      <div class="section2 margin-top">
        <div class="container">
            <div class="row sidbar-row">
                <div class="col-lg-3 sidbar-col">
                    <div class="table-sec sidbar-box " >
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <div class="container px-0 d-block">
                              <div class="d-flex pro-head">
                                <div>
                                  <h4 class="chart-title w afacad">Introduction</h4>
                                </div>
                              <div>
                              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                              </button>
                            </div>
                              </div>
                              <hr class="w hr-line"/>
                              <div class="collapse navbar-collapse" id="navbarSupportedContent1">
                                <ul class="navbar-nav d-block me-auto mb-2 mb-lg-0">
                                  <h4 class="chart-title w afacad">Getting Started</h4>
                                  <li class="nav-item">
                                    <a class="nav-link active w inter" aria-current="page" href="#">Creating an Account</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Getting an API Key</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Endpoint URLs</a>
                                  </li>
                                  <h4 class="chart-title w afacad">API Endpoints</h4>
                                 <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Accounts</a>
                                  </li> 
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Contracts</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Transactions</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Blocks</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Logs</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Geth Proxy</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Tokens</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Gas Tracker</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Stats</a>
                                  </li>
                                  <h4 class="chart-title w afacad">API PRO</h4>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">BscScan API PRO</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> API PRO Endpoints</a>
                                  </li>
                                  <h4 class="chart-title w afacad">Tutorials</h4>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Integrating Google Sheets</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#"> Verifying Contracts Programmatically</a>
                                  </li>
                                  <h4 class="chart-title w afacad">Misc Tools & Utilities</h4>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Libraries</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Plugins</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Public RPC Nodes</a>
                                  </li>
                                  <h4 class="chart-title w afacad">Support</h4>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">FAQ</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Rate Limits</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Common Error Messages</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Getting Help</a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link w inter" href="#">Visit GlcScan.com</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </nav>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="table-sec" style={{height:" 100%"}}>  
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default APIDocumentation

