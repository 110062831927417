// import React, { useState, useEffect } from "react";
// import { defaults } from "chart.js/auto";
// import { Line } from "react-chartjs-2";
// import { base_url } from '../../config/Api';
// defaults.maintainAspectRatio = false;
// defaults.responsive = true;

// defaults.plugins.title.display = true;
// defaults.plugins.title.align = "start";
// defaults.plugins.title.font.size = 20;
// defaults.plugins.title.width = 500;

// const LineChart = () => {
//   const [revenueData, setRevenueData] = useState({
//     chartLabels: [],
//     chartValues: [],
//     // Add other necessary properties here...
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${base_url}/api/transaction/chart`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         if (typeof data === 'object' && data !== null) {
//           setRevenueData(data);
//         } else {
//           console.error("Fetched data is not in the expected format:", data);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);
  

//   return (
//     <div className=" " style={{ height: 250, marginTop: -40 }}>
//       <Line
//         data={{
//           labels: revenueData.chartLabels.map(date => new Date(date).toLocaleDateString('en-Us',{month:'short',day:'numeric'})), // Convert dates to readable format
//           // labels:filteredLabels,
//           datasets: [
//             {
//               label: "",
//               data: revenueData.chartValues,
//               backgroundColor: "rgba(6, 79, 240, 0.2)",
//               borderColor: "#d0f459",
//               borderWidth: 2,
//               pointBackgroundColor: "#d0f459",
//               pointRadius: 2,
//               pointHoverRadius: 8,
//             },
//           ],
//         }}
//         options={{
//           elements: {},
//           plugins: {
//             legend: { display: false },
//             tooltip: {
//               callbacks: {
//                 label: function (context) {
//                   const label = context.dataset.label || "";
//                   if (label) {
//                     return `${label}: ${context.formattedValue}`;
//                   }
//                   return `${context.dataset.data[context.dataIndex]}`;
//                 },
//               },
//             },
//           },
//           scales: {
//             x: {
//               grid: {
//                 display: true,
//                 color: "rgba(0, 0, 0, 0.1)",
//               },
//               ticks: {
//                 color: "#d0f459",
//               },
//             },
//             y: {
//               grid: {
//                 display: true,
//                 color: "#d0f459",
//               },
//               ticks: {
//                 color: "#d0f459",
//               },
//             },
//           },
//           maintainAspectRatio: false,
//           responsive: true,
//           width: 800,
//           height: 400,
//           legend: { display: false },
//         }}
//       />
//     </div>
//   );
// };

// export default LineChart;
import React, { useState, useEffect } from "react";
import { defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { base_url } from '../../config/Api';

defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.width = 500;

const LineChart = () => {
  const [revenueData, setRevenueData] = useState({
    chartLabels: [],
    chartValues: [],
    priceChartDataValues: [],
    dailyGasPriceValues: [],
    avgGasPriceValues: [],
    blockCount: [],
    totalReward: [],
    transactionFee: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}/api/transaction/chart`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (typeof data === 'object' && data !== null) {
          // Reverse the arrays
          for (const key in data) {
            if (Array.isArray(data[key])) {
              data[key] = data[key].reverse();
            }
          }
          setRevenueData(data);
        } else {
          console.error("Fetched data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className=" " style={{ height: 250, marginTop: -40 }}>
      <Line
        data={{
          labels: revenueData.chartLabels.map(date => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })),
          datasets: [
            {
              label: "",
              data: revenueData.chartValues,
              backgroundColor: "rgba(6, 79, 240, 0.2)",
              borderColor: "#d0f459",
              borderWidth: 2,
              pointBackgroundColor: "#d0f459",
              pointRadius: 2,
              pointHoverRadius: 8,
            },
          ],
        }}
        options={{
          elements: {},
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label || "";
                  if (label) {
                    return `${label}: ${context.formattedValue}`;
                  }
                  return `${context.dataset.data[context.dataIndex]}`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                color: "#d0f459",
              },
            },
            y: {
              grid: {
                display: true,
                color: "#d0f459",
              },
              ticks: {
                color: "#d0f459",
              },
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 800,
          height: 400,
          legend: { display: false },
        }}
      />
    </div>
  );
};

export default LineChart;




// import React, { useState, useEffect } from "react";
// import CanvasJSReact from '@canvasjs/react-charts';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const LineChart = () => {
//   const [revenueData, setRevenueData] = useState({
//     chartLabels: [],
//     chartValues: [],
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("https://aibotapi.metagainfx.com/api/transaction/chart");
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         if (typeof data === 'object' && data !== null) {
//           setRevenueData(data);
//         } else {
//           console.error("Fetched data is not in the expected format:", data);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const options = {
//     theme: "light2",
//     animationEnabled: true,
//     zoomEnabled: true,
//     title: {
//       text: "", // Title text
//       fontSize: 20,
//     },
//     data: [{
//       type: "line",
//       dataPoints: revenueData.chartLabels.slice(0, 14).map((date, index ) => ({ x: index + 1, y: revenueData.chartValues[index] })),
      
//       backgroundColor: "rgba(6, 79, 240, 0.2)",
//                borderColor: "#d0f459",
//                borderWidth: 1,
//                pointBackgroundColor: "#d0f459",
//               //  pointRadius: 2,
//                pointHoverRadius: 8,lineColor: "#d0f459",
//       markerColor: "#d0f459",
//       markerSize: 1,
//       markerType: "circle",
//     }]
//   };

//   return (
//     <div style={{ height: 250, marginTop: -40 }}>
//       <CanvasJSChart options={options} />
//     </div>
//   );
// };

// export default LineChart;

// import React, { useState, useEffect } from "react";
// import { defaults } from "chart.js/auto";
// import { Line } from "react-chartjs-2";
// import Skeleton from "react-loading-skeleton";

// defaults.maintainAspectRatio = false;
// defaults.responsive = true;

// defaults.plugins.title.display = true;
// defaults.plugins.title.align = "start";
// defaults.plugins.title.font.size = 20;
// defaults.plugins.title.width = 500;

// const LineChart = () => {
//   const [revenueData, setRevenueData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("https://aibotapi.metagainfx.com/api/transaction/chart");
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         if (typeof data === 'object' && data !== null) {
//           setRevenueData(data);
//         } else {
//           console.error("Fetched data is not in the expected format:", data);
//         }
//         setLoading(false); // Data fetching completed
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setLoading(false); // Error occurred while fetching data
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className=" " style={{ height: 250, marginTop: -40 }}>
//       {loading || !revenueData ? (
//         // Render skeleton loading when loading or data is null
//         <Skeleton height={250} />
//       ) : (
//         <Line
//           data={{
//             labels: revenueData.chartLabels.slice(0, 14).map(date => new Date(date).toLocaleDateString('en-Us',{month:'short',day:'numeric'})), // Convert dates to readable format
//             datasets: [
//               {
//                 label: "",
//                 data: revenueData.chartValues.slice(0, 14),
//                 backgroundColor: "rgba(6, 79, 240, 0.2)",
//                 borderColor: "#d0f459",
//                 borderWidth: 2,
//                 pointBackgroundColor: "#d0f459",
//                 pointRadius: 2,
//                 pointHoverRadius: 8,
//               },
//             ],
//           }}
//           options={{
//             elements: {},
//             plugins: {
//               legend: { display: false },
//               tooltip: {
//                 callbacks: {
//                   label: function (context) {
//                     const label = context.dataset.label || "";
//                     if (label) {
//                       return `${label}: ${context.formattedValue}`;
//                     }
//                     return `${context.dataset.data[context.dataIndex]}`;
//                   },
//                 },
//               },
//             },
//             scales: {
//               x: {
//                 grid: {
//                   display: true,
//                   color: "rgba(0, 0, 0, 0.1)",
//                 },
//                 ticks: {
//                   color: "#d0f459",
//                 },
//               },
//               y: {
//                 grid: {
//                   display: true,
//                   color: "#d0f459",
//                 },
//                 ticks: {
//                   color: "#d0f459",
//                 },
//               },
//             },
//             maintainAspectRatio: false,
//             responsive: true,
//             width: 800,
//             height: 400,
//             legend: { display: false },
//           }}
//         />
//       )}
//     </div>
//   );
// };

// export default LineChart;
