import React from "react";
const TermsandConditions = () => {
  return (
    <>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec">
            <h4 className="chart-title w afacad">Please Carefully Review Our Terms.</h4>
            <p className="icon-box-title bl inter mb-2">By using our services, you agree to abide by these Terms and any referenced terms at
              GLCScan. These terms govern your access to and use of all services provided by
              GLCScan.com.
            </p>

            <h4 className="chart-title w afacad">1. ELIGIBILITY</h4>
            <p className="icon-box-title bl inter mb-2">You confirm that: (a) you meet the legal requirements for a binding contract; (b) you have not
              been previously suspended or barred from accessing our Services; and (c) you have the full
              authority and capability to enter this agreement without breaching any other agreements</p>
            <p className="icon-box-title bl inter mb-2">If you register for our Services on behalf of a legal entity, you also confirm that (i) the entity is
              lawfully organized and validly existing under applicable laws, and (ii) you are duly authorized
              by the entity to represent it.</p>
            <h4 className="chart-title w afacad">2. ACCOUNT REGISTRATION</h4>
            <p className="icon-box-title bl inter mb-2">To access our Services, you must create an account with the Company ("Account"). By creating
              an Account, you agree to:</p>
            <p className="icon-box-title bl inter mb-2">(a) Create a robust, unique password, not used elsewhere for any website or online service</p>
            <p className="icon-box-title bl inter mb-2">(b)  Provide accurate and truthful information.</p>
            <p className="icon-box-title bl inter mb-2">(c) Keep your Account information current and promptly updated.
            </p>
            <p className="icon-box-title bl inter mb-2">(d) Safeguard your Account security by protecting your password and limiting access to your
              computer and Account.</p>
            <p className="icon-box-title bl inter mb-2">(e) Immediately report any Account-related security breaches to us.</p>
            <p className="icon-box-title bl inter mb-2">(f)Assume full responsibility for all Account activities, whether authorized or unauthorized, as
              permitted by law.</p>
            <p className="icon-box-title bl inter mb-2">Upon Account creation, we will assign you an account identifier, which you must keep for
              accessing your Account.</p>
            <h4 className="chart-title w afacad">3. TERMINATION OF SERVICES</h4>
            <p className="icon-box-title bl inter mb-2">We retain the right, solely at our discretion and without any liability to you, to alter or cease
              any part of our Services, either temporarily or permanently, with or without advance notice, at
              any juncture.</p>
            <h4 className="chart-title w afacad">4.ACKNOWLEDGEMENT OF RISK</h4>
            <p className="icon-box-title bl inter mb-2">By utilizing our Internet-based services, you acknowledge and accept the inherent risks
              involved, which may include hardware or software failures, disruptions in Internet connectivity,
              introduction of malicious software, and potential unauthorized access to your GLCScan
              Account by third parties.
            </p>
            <p className="icon-box-title bl inter mb-2">You also understand and agree that the Company assumes no liability for any communication
              failures, disruptions, errors, distortions, or delays encountered while using our Services.
              Additionally, the Company will not be held responsible for any losses, damages, or claims
              arising from:</p>
            <p className="icon-box-title bl inter mb-2">(a) Bruteforce" attacks on passwords..</p>
            <p className="icon-box-title bl inter mb-2">(b) Server failures or data loss incidents..</p>
            <p className="icon-box-title bl inter mb-2">(c) Instances of
              forgotten passwords.,</p>
            <p className="icon-box-title bl inter mb-2">(d)  Corruption of wallet files</p>
            <p className="icon-box-title bl inter mb-2">(e) Errors in transaction construction or
              misidentification of BNB Smart Chain addresses.</p>
            <p className="icon-box-title bl inter mb-2">(f) Unauthorized access to mobile
              applications.</p>
            <p className="icon-box-title bl inter mb-2">(g) Phishing" attempts, viruses, third-party attacks, or any other unauthorized
              activities by third parties.</p>
            <h4 className="chart-title w afacad">5. THIRD-PARTY SERVICES AND CONTENT</h4>
            <p className="icon-box-title bl inter mb-2">During your use of our Services, you may come across content or utilize services provided by
              third parties, including links to external web pages and services ("Third-Party Content").
            </p>
            <p className="icon-box-title bl inter mb-2">We want to make it clear that we neither control nor endorse any Third-Party Content and
              assume no responsibility for its accuracy, completeness, legality, or appropriateness. Your
              engagement with third parties and use of Third-Party Content are entirely at your own risk</p>
            <p className="icon-box-title bl inter mb-2">You acknowledge that any transactions or correspondence with third parties are solely your
              responsibility, and we are not liable for any losses or damages resulting from such interactions.</p>
            <h4 className="chart-title w afacad">6. ACCEPTABLE USE</h4>
            <p className="icon-box-title bl inter mb-2">By accessing or using our Services, you agree to comply with all applicable laws, regulations, and third-party
              rights. Specifically, you agree not to:
            </p>
            <p className="icon-box-title bl inter mb-2"><b>Disruptive Activities:-  </b>
              Use our Services in any way that could interfere with, disrupt, negatively affect, or inhibit
              other users from fully enjoying our Services, or that could damage, disable, overburden, or impair the
              functioning of our Services.

            </p>
            <p className="icon-box-title bl inter mb-2">
              <b>Illegal Activities:- </b> Use our Services to engage in any illegal activities, including but not limited to illegal gambling, fraud, money laundering, or terrorism</p>
            <p className="icon-box-title bl inter mb-2"><b>Unauthorized Access</b>
              Use any robot, spider, crawler, scraper, or other automated means or interface not
              provided by us to access our Services or extract data.</p>
            <p className="icon-box-title bl inter mb-2"><b>Data Collection:- </b>
              Engage in automated data collection (scraping) unless it is solely for search indexing for public
              display on the Internet.</p>
            <p className="icon-box-title bl inter mb-2"><b>Content Reproduction:- </b>
              Reproduce any content from our APIs, CSV exports, website, or affiliate websites
              without our prior written consent.</p>
            <p className="icon-box-title bl inter mb-2"><b>Account Misuse:- </b>
              : Use or attempt to use another user’s account without proper authorization.</p>
            <p className="icon-box-title bl inter mb-2"> <b>Security Breaches:-</b>
              Circumvent any content filtering techniques we employ or attempt to access any
              unauthorized areas of our Services.
            </p>
            <p className="icon-box-title bl inter mb-2"><b>Malicious Software:-</b>
              Introduce any viruses, trojans, worms, logic bombs, or other harmful material into our
              Services.</p>
            <p className="icon-box-title bl inter mb-2"><b>Third-Party Applications:-</b>
              Develop any third-party applications that interact with our Services without our prior
              written consent</p>
            <p className="icon-box-title bl inter mb-2"><b>Encouraging Violations: </b>
              Encourage or induce any third party to engage in any of the activities prohibited by
              these terms</p>

            <h4 className="chart-title w afacad">7. USER-GENERATED CONTENT</h4>
            <p className="icon-box-title bl inter mb-2">7.1. Responsibility for User-Generated Content - You are solely responsible for any content you post, upload, link to, or otherwise make available via our Services. This includes
              any consequences or harm resulting from your content. We are not liable for any public display or misuse of your content. We
              reserve the right to refuse or remove any User-Generated Content that, in our sole discretion, violates our terms or policies..</p>
            <p className="icon-box-title bl inter mb-2">7.2.  Ownership of Content & Right to Post - You must only post content that you have created or have the right to post. If you post content you do not own, you agree to be
              fully responsible for that content and to comply with any relevant third-party licenses.</p>
            <p className="icon-box-title bl inter mb-2">7.3. License Grant to Us - By posting content on our Services, you grant us and our successors a non-exclusive, royalty-free, worldwide license to store,
              parse, display, and make incidental copies of your content. This license enables us to effectively operate and provide our
              Services to you and other users.</p>
            <p className="icon-box-title bl inter mb-2">7.4. Moral Rights - You retain all moral rights to your content. However, to allow us to effectively provide our Services, you waive these rights and
              agree not to assert them against us.</p>
            <p className="icon-box-title bl inter mb-2">7.5. Compliance with Law - If any part of this agreement is found unenforceable under applicable law, you grant us the necessary rights to use your content
              without attribution and to make reasonable adaptations to deliver our Services.</p>
            <h4 className="chart-title w afacad">8. COPYRIGHTS AND OTHER INTELLECTUAL PROPERTY RIGHTS
            </h4>
            <p className="icon-box-title bl inter mb-2">Unless explicitly stated otherwise by GLCScan, all copyright and other intellectual property rights in the content and materials
              on our website or provided with the Services, including the GLCScan logo, designs, text, graphics, images, data, software,
              sound files, and other files ("GLCScan Materials"), belong to GLCScan, our licensors, or suppliers, and are protected by
              copyright and other intellectual property laws.</p>
            <p className="icon-box-title bl inter mb-2">Unauthorized use or reproduction of this material without express written permission from GLCScan or the respective owner is
              strictly prohibited. However, limited excerpts and links may be used, provided full and clear credit is given to GLCScan.com
              with appropriate and specific direction to the original content.
            </p>
            <h4 className="chart-title w afacad">9. TRADEMARKS</h4>
            <p className="icon-box-title bl inter mb-2">"The names "GLCScan," our Company logo, and any other content displayed on our portal cannot be replicated
              or used in any form without our prior written approval.</p>
            <p className="icon-box-title bl inter mb-2">You are not allowed to use any Company trademarks, product names, or service names without our explicit
              written consent. This restriction includes the use of metatags or hidden text featuring any Company trademarks,
              product names, or service names. The visual elements of our Services ( page headers, graphics, button icons,
              and scripts) constitute the trade dress of the Company and also require written consent for use.</p>
            {/* <p className="icon-box-title bl inter mb-2">All other trademarks, registered trademarks, product names, and company names or logos mentioned through our Services are the property of their respective owners. Reference to any products, services, processes, or other information, by name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by us at GLCScan.</p> */}
            <h4 className="chart-title w afacad">10. TERMINATION</h4>
            <p className="icon-box-title bl inter mb-2">In the event of a Force Majeure Event, breach of these Terms, or any situation rendering provision of our
              Services impractical, we may, without liability, suspend or terminate your access to GLCScan. This may be
              done with or without prior notice</p>
            <p className="icon-box-title bl inter mb-2">We reserve the right to immediately terminate your access and deactivate your Account if you violate these
              Terms. We will not be liable for such actions.</p>
            <h4 className="chart-title w afacad">11. COOKIE STATEMENT</h4>
            <p className="icon-box-title bl inter mb-2">This site uses cookies, which are small text files placed on your computer by websites you visit. Cookies help
              websites function efficiently and provide information to site owners. At GLCScan, cookies are stored on your
              computer's hard drive.</p>
            <p className="icon-box-title bl inter mb-2">We use cookie-collected information to evaluate site effectiveness, analyze trends, and manage the platform.
              This helps us improve user experience by recognizing preferred features, resolving access issues, and
              personalizing email interactions</p>
            <p className="icon-box-title bl inter mb-2">Third-party service providers assist us in understanding site usage. They place cookies on your computer's hard
              drive and provide us with aggregate reports on site navigation, page views, and transaction data. These insights
              help us better serve user interests. Providers are contractually restricted from using site information for any
              purpose other than assisting us at GLCScan</p>
            <p className="icon-box-title bl inter mb-2">By continuing to use this site, you consent to cookies being stored on your device at GLCScan.</p>
            <h4 className="chart-title w afacad">12. PRIVACY POLICY</h4>
            <p className="icon-box-title bl inter mb-2">For detailed information on privacy, please refer to our Privacy Policy at GLCScan.</p>
            <h4 className="chart-title w afacad">13. DISCLAIMER OF WARRANTIES</h4>
            <p className="icon-box-title bl inter mb-2">(a)  Our services are provided "as is" and "as available" to the maximum extent permitted by law. We expressly
              disclaim all warranties, whether express or implied, including merchantability, fitness for a particular purpose,
              title, and non-infringement.</p>
            <p className="icon-box-title bl inter mb-2">(b) You acknowledge that information stored or transferred through our services may be lost or corrupted due
              to various causes beyond our control. You are responsible for backing up any information you store or transfer.

              </p>
              <p className="icon-box-title bl inter mb-2"> Certainly! Here's a concise version of the Limitation of Liability and Indemnity clauses:
              
              </p>
            <h4 className="chart-title w afacad">14. LIMITATION OF LIABILITY</h4>
            <p className="icon-box-title bl inter mb-2">Company, our directors, employees, or agents shall not be liable for any special, indirect, or consequential 
damages arising from the use of our services or Company Materials, including loss of use, profits, or data, 
regardless of the cause.</p>
            <h4 className="chart-title w afacad">15. INDEMNITY</h4>
            <p className="icon-box-title bl inter mb-2">You agree to defend and indemnify Company and its officers, directors, employees, and affiliates from any 
claims, damages, costs, or expenses arising from your use of our Services, any Feedback provided, or any 
violation of these Terms</p>
            {/* <p className="icon-box-title bl inter mb-2">(a) your use of, or conduct in connection with, our Services;</p>
            <p className="icon-box-title bl inter mb-2">(b) any Feedback you provide;</p>
            <p className="icon-box-title bl inter mb-2">(c) your violation of these Terms; or</p>
            <p className="icon-box-title bl inter mb-2">(d) your violation of any rights of any other person or entity.</p> */}
            <h4 className="chart-title w afacad">16. MISCELLANEOUS</h4>
            <p className="icon-box-title bl inter mb-2">16.1. Entire Agreement; Order of Precedence  :- These Terms constitute the entire agreement between the parties 
and supersede all prior understandings regarding the Services. These Terms do not modify any other 
agreements you may have with GLCscan. In case of any conflict with other agreements, those agreements will 
prevail only if specifically identified to override these Terms.</p>
            <p className="icon-box-title bl inter mb-2">16.2. Amendment. GLCscan reserves the right to amend these Terms at any time. Amendments will be effective 
immediately upon posting unless otherwise stated. Your continued use of the Services after any changes 
constitutes acceptance of the amended Terms.</p>
            <p className="icon-box-title bl inter mb-2">16.3. Waiver.  Our failure or delay in enforcing any right under these Terms does not waive that right.</p>
            <p className="icon-box-title bl inter mb-2">16.4. Force Majeure Events: .GLCscan will not be liable for any loss or damage resulting from events beyond its 
reasonable control, such as natural disasters or government actions.</p>
           
            <p className="icon-box-title bl inter mb-2">16.6. Assignment. You may not assign your rights or obligations under these Terms without prior written consent 
from GLCscan. GLCscan may assign its rights without your consent.</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default TermsandConditions;