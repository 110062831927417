
import React, { useState } from 'react';
// import black from './Img/black.png';
import Green from './Img/Green.png'
import { useNavigate,NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import { base_url } from './config/Api';

const Forgetpassword = () => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email.trim()) {
        toast.dismiss()
        toast.error('Email is required.');
        return;
      }
      const response = await fetch(`${base_url}/api/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
     

      console.log('data', data.success )
      if (data.success === true) {
        
        
        toast.dismiss()

       
      
        toast.success(data?.message )
        navigate('/LoginPage');
      } else {
        toast.dismiss()
        toast.error(data?.message)
      }
    } catch (error) {
      console.error('Error:', error);
     
    }
  };

  return (
    <div>
      <div className="section2 mt-5">
        <div className="container">
          <div className="table-sec table-sec1">
            <div className="row">
              <div className="col-lg-7 login-form py-5">
                <form className="py-3" onSubmit={handleSubmit}>
                  <label className="form-label1 w afacad" htmlFor="email">
                    Email Address:
                  </label>
                  <div className="d-flex search-form mt-3">
                    <div className="user-icon">
                      <i className="fa-solid fa-user b"></i>
                    </div>
                    <input
                      className="form-control me-2 search-input inter"
                      id="email"
                      type="email"
                      autocomplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br />
                  <button className="form-submit afacad b Login" type="submit">
                    Submit
                  </button>
                </form>
                {responseMessage && <p id='sd'>{responseMessage}</p>}
              </div>
              <div className="col-lg-5 login-form-col2">
                <div className="login-box">
                  <img className="logo-sign" src={Green} alt="" />
                  <h4 className="form-title b afacad" >Forgot Password</h4>
                <p className="form-label1 b inter " >
                If You know Id And Password?
                  <NavLink to="/LoginPage" className="b form-label1 inter">
                    Sign In
                  </NavLink>
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;

