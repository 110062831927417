import React from "react";
import gl_logoa from '../Img/gl_logoa.png'
import { NavLink } from "react-router-dom";
const Footer = () => {

  return (
    <>

      <footer>
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <NavLink to="/">
                <img src={gl_logoa} alt="logo" className='mainlogo' /></NavLink>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <h4 class="chart-title w afacad mb-2">Useful Links</h4>
              <ul class="useful-list">
                <li> <NavLink to="/GLCGuide" className="useful-list-item w inter" activeClassName="active-link">
                  GLC Guide
                </NavLink>
                </li>
                <li> <NavLink to="/GLC20Devportal" className="useful-list-item w inter" activeClassName="active-link"> GLC-20 Dev Portal </NavLink></li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <h4 class="chart-title w afacad mb-2"> GLC-20 Scan</h4>
              <ul class="useful-list">
                <li> <NavLink to="/contactus" className="useful-list-item w inter" activeClassName="active-link"> Contact Us </NavLink></li>
                <li> <NavLink to="/Policy" className="useful-list-item w inter" activeClassName="active-link"> Privacy Policy </NavLink></li>
                <li>  <NavLink to="/TermsandConditions" className="useful-list-item w inter" activeClassName="active-link"> Terms and Conditions </NavLink></li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
              <h4 class="chart-title w afacad mb-2">Social Media </h4>
              <div class="social-media">
                <a href="https://www.facebook.com/bitnetto.io"><i class="fa-brands fa-facebook b"></i></a>
                <a href="https://t.me/+nrBsNrfuCf1mYmVl"><i class="fa-brands fa-telegram b"></i></a>
                <a href="https://glcasset.com/Dashboard/User/neWLogin"><i class="fa-solid fa-globe b"></i></a>
                <a href="https://www.youtube.com/@bitnetto"><i class="fa-brands fa-youtube b"></i></a>
                <a href="https://www.instagram.com/bitnetto.io/"><i class="fa-brands fa-instagram b"></i></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer;
