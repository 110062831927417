import React, { useState, useEffect } from 'react';
import Green from '../../Img/Green.png';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { base_url } from '../../config/Api';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3'; 

const Transactions = () => {
  const [latestTransactions, setLatestTransactions] = useState([]);
  const [latestBlocks, setLatestBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const web3Instance = new Web3('https://glc-dataseed.glcscan.co.io/'); // Replace with your Ethereum node URL if needed
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [transactionsResponse, blocksResponse] = await Promise.all([
          fetch(`${base_url}/api/transaction`),
          fetch(`${base_url}/api/blocks`)
        ]);

        if (!transactionsResponse.ok) {
          throw new Error(`Failed to fetch transactions. Status: ${transactionsResponse.status}`);
        }

        const transactionsResult = await transactionsResponse.json();

        if (transactionsResult && Array.isArray(transactionsResult.transactions)) {
          setLatestTransactions(transactionsResult.transactions.slice(0, 3));
        } else {
          console.error('Invalid or missing transaction data in the API response:', transactionsResult);
        }

        if (!blocksResponse.ok) {
          throw new Error(`Failed to fetch blocks. Status: ${blocksResponse.status}`);
        }

        const blocksResult = await blocksResponse.json();

        if (blocksResult && Array.isArray(blocksResult.blocks)) {
          setLatestBlocks(blocksResult.blocks.slice(0, 3));
        } else {
          console.error('Invalid or missing block data in the API response:', blocksResult);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const copyText = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dismiss()
    toast.success("Text copied to clipboard!");
  };

  const renderTransaction = (data, type) => {
    return (
      <div className='container'>
        <h4 className="chart-title w afacad">Latest Transactions </h4>
        <div className="list">
          {loading ? (
            <Skeleton count={3} height={60} baseColor="#202020" highlightColor="#444" />
          ) : (
            data.map((item) => (
              <div className="last_block d-flex" key={item.id?.slice(0, 0)}>
                <div className=" d-flex icon-box">
                  <div>
                    <div className="icon-box-icon01 phon_d">
                      <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                    </div>
                  </div>
                  <div className="icon-box-text">
                    <p className="last_text g">
                      <span className="w">
                        <NavLink to={`/tx/${item.hash}`} className="g">
                          {item.hash?.slice(0, 8)}
                        </NavLink>
                      </span>
                    </p>
                    <p className="last_text g">
                      <span className="w">
                        {item.updatedAt || 'N/A'}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="rbc">
                  <p className="last_text g">
                    <span className="w">
                      From
                      <NavLink to={`/address/${item.from}`} id='op' className="g">
                        {item.from?.slice(0, 8)}
                      </NavLink>
                    </span>
                    <button
                      className="copy-btn"
                      onClick={() => copyText(item.from)}
                    >
                      <i className="fa-regular fa-copy g"></i>
                    </button>
                  </p>
                  <p className="last_text g">
                    <span className="w ">
                      To <NavLink to={`/address/${item.to}`} id='op' className="g">
                        {item.to?.slice(0, 10)}
                      </NavLink>
                    </span>
                    <button
                      className="copy-btn"
                      onClick={() => copyText(item.to)}
                    >
                      <i className="fa-regular fa-copy g"></i>
                    </button>
                  </p>
                </div>
                <div className="rbc">
                  <p className="last_text g">
                    
                    <span className="last_text-bg w">{item.value ? parseFloat(web3Instance.utils.fromWei(item.value, 'ether')).toFixed(4) : null}</span>

                  </p>
                  <p className="last_text g">
                    {/* <span className="last_text-bg w last_text-bg1" id='r'>{item.blockNumber}</span> */}
                    {/* <span className="last_text-bg w last_text-bg1">{item.blockNumber ? item.blockNumber : null}</span> */}

                  </p>
                </div>
              </div>
            ))
          )}
        </div>
        <NavLink to='/BLockchain' className="view-btn b afacad">
          View all latest {type}
        </NavLink>
      </div>
    );
  };

  const renderBlock = (data, type) => {
    return (
      <div className='container'>
        <h4 className="chart-title w afacad"> Latest Blocks </h4>
        <div className='list'>
          {loading ? (
            <Skeleton count={3} height={60}  baseColor="#202020" highlightColor="#444"/>
          ) : (
            data.map((item, index) => (
              <div className="last_block d-flex" key={item.id?.slice(0, 0)}>
                <div className="d-flex icon-box">
                  <div>
                    <div className="icon-box-icon01 phon_d">
                      <img className=' box-icon supplylogo ' src={Green} alt="logo" />
                    </div>
                  </div>
                  <div className="icon-box-text">
                    <p className="last_text g">
                      <span className="w"></span>
                      <NavLink className="g" to={`/blockhash/${item.blockNumber}`}>{item.blockNumber}</NavLink>
                    </p>
                    <p className="last_text g">
                      <span className="w">{item.elapsedTime || 'N/A'}</span>
                    </p>
                  </div>
                </div>
                <div className="rbc">
                  <p className="last_text g">
                    <span className="w">
                      Block hash  
                      <NavLink to={`/blockhash/${item.blockHash}`} id='op' className="g">
                        {item.blockHash?.slice(0, 8)}
                      </NavLink>
                       {/* <NavLink to={`/tx/${item.blockHash}`} id='op' className="g">
                        {item.blockHash?.slice(0, 8)}
                      </NavLink> */}
                      
                      
                    </span>
                    {/* <span className="g p-1" > */}
                    {/* {item.blockHash?.slice(0, 8)} */}
                    {/* </span> */}
                    <button
                      className="copy-btn"
                      onClick={() => copyText(item.blockHash)}
                    >
                      <i className="fa-regular fa-copy g"></i>
                    </button>
                  </p>
                  <p className="last_text g">
                    <span className="w">
                      leader
                      <NavLink to={`address/${item.from}`} id='op' className="g">
                        {item.from?.slice(0, 10)}
                      </NavLink>
                    </span>
                    <button
                      className="copy-btn"
                      onClick={() => copyText(item.from)}
                    >
                      <i className="fa-regular fa-copy g"></i>
                    </button>
                  </p>
                </div>
                <div className="rbc">
                  <p className="last_text g">
                  <span className="last_text-bg w">{item.value ? parseFloat(web3Instance.utils.fromWei(item.value, 'ether')).toFixed(4) : null}</span>

                  </p>
                </div>
              </div>
            ))
          )}
        </div>
        <NavLink to='/Blockesd' className="view-btn b afacad">
          View all latest {type}
        </NavLink>
      </div>
    );
  };

  return (
    <div className="section2 mb-4">
      <div className="container t">
        <div className="row section2-box mx-1 mt-4">
          <div className="col-lg-6 p-1">
            <div className="col_02">
              <div className="last_block transactions-container">
                {renderTransaction(latestTransactions, 'Transactions')}
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="col_02">
              <div className="last_block transactions-container">
                {renderBlock(latestBlocks, 'Blocks')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;