
import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { base_url } from '../../../config/Api';
import { toast } from 'react-toastify'; 
import Web3 from 'web3'; 
const Table = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [cumulativeIndex, setCumulativeIndex] = useState(0);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const web3Instance = new Web3('https://glc-dataseed.glcscan.co.io/'); // Replace with your Ethereum node URL if needed
  useEffect(() => {
    fetchTransactions();
  }, [currentPage, selectedItemsPerPage]);

  const fetchTransactions = async () => {
    try {
      setLoading(true); 

      const response = await fetch(`${base_url}/api/transaction?page=${currentPage}&limit=${selectedItemsPerPage}`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch transactions. Status: ${response.status}`);
      }

      const result = await response.json();

      if (result && Array.isArray(result.transactions)) {
        setTransactions(result.transactions);
        setTotalTransactions(result.totalTransactions);
        const newCumulativeIndex = (currentPage - 1) * selectedItemsPerPage; // Calculate new CumulativeIndex
        setCumulativeIndex(newCumulativeIndex); // Update CumulativeIndex
      } else {
        console.error('Invalid or missing transaction data in the API response:', result);
      }

      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching transaction data:', error);
    }
  };

  const handleItemsPerPageChange = (selectedValue) => {
    setSelectedItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const pagination = () => {
  //   const totalPages = Math.ceil(totalTransactions / selectedItemsPerPage);
  //   const activePage = currentPage;
  //   const pages = [];

  //   pages.push(
  //     <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
  //     <Pagination.Prev key="prev" onClick={() => handlePageChange(activePage - 1)} />
  //   );

  //   for (let i = Math.max(1, activePage - 2); i <= Math.min(totalPages, activePage + 2); i++) {
  //     pages.push(
  //       <Pagination.Item key={i} active={i === activePage} onClick={() => handlePageChange(i)}>
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }

  //   pages.push(
  //     <Pagination.Next key="next" onClick={() => handlePageChange(activePage + 1)} />,
  //     <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
  //   );

  //   return <Pagination>{pages}</Pagination>;
  // };

  const pagination = () => {
    const totalPages = Math.ceil(totalTransactions / selectedItemsPerPage);
    const pages = [];

    pages.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
        pages.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }

    pages.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />,
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return <Pagination>{pages}</Pagination>;
};


  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()

    toast.success("Text copied to clipboard!");
  };

  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="table-sec ">
            <div className='tab'>
              <h4 className="chart-title w afacad" id='pod'>{`Total Transactions Found:  ${totalTransactions} `}</h4>
              <div>
                <select value={selectedItemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className='transactions'>
              {loading ? (
                <Skeleton count={10} height={50} baseColor="black" highlightColor="green"/>
              ) : (
                <table className="my-3 found-table5">
                  <thead>
                    <tr className="table-row ">
                      <th className="tabel-heading w afacad">#</th>
                      <th className="tabel-heading w afacad">Block</th>
                      <th className="tabel-heading w afacad">txnhash</th>
                      <th className="tabel-heading w afacad">Age</th>
                      <th className="tabel-heading w afacad">From</th>
                      <th className="tabel-heading w afacad">To</th>
                      <th className="tabel-heading w afacad">Value</th>
                      <th className="tabel-heading w afacad">Txn fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((txn, index) => (
                      <tr className="table-row" key={index}>
                        <td className="tabel-text w inter">{cumulativeIndex + index + 1}</td>
                        <td className="tabel-text w inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <a href="#" className="bhcjb w">
                                {txn.blockNumber}
                              </a>
                            </span>
                          </p>
                        </td>
                        <td className="tabel-text w inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/tx/${txn.hash}`} style={{ color: "white" }} className="bhcjb w">
                                {txn.hash}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(txn.hash)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        <td className="tabel-text w inter">
                          {txn.updatedAt}
                        </td>
                        <td className="tabel-text w inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/Address/${txn.from}`} className="bhcjb w">
                                {txn.from}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(txn.from)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        <td className="tabel-text inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/Address/${txn.to}`} className="bhcjb w">
                                {txn.to}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(txn.to)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        {/* <td className="tabel-text w inter">{txn.value?.slice(0, 8)}</td> */}
                        <td className="tabel-text w inter">{txn.value? parseFloat(web3Instance.utils.fromWei(txn.value, 'ether')).toFixed(4) : <Skeleton width={50} />}</td>
                        {/* <td className="tabel-text w inter">{txn.gasPrice}</td> */}
                        <td className="tabel-text w inter"> {txn.gasPrice ? parseFloat(web3Instance.utils.fromWei(txn.gasPrice, 'ether')).toFixed(9) : null}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {pagination()}
            <button onClick={() => setSidebarVisible(!isSidebarVisible)}>
              Toggle Sidebar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;

