import React, { useState, useEffect } from "react";

const Veiwtoken=()=>{
  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="table-sec ">
          <h4 className="chart-title w afacad">More Than 0 Transactions Found</h4>
          <div className="transactions">
          <table className="my-3 found-table5">
            <tr className="table-row">
                    <th className="tabel-heading w afacad">Sr no</th>
                    <th className="tabel-heading w afacad">Token</th>
                    <th className="tabel-heading w afacad">Price</th> 
                    <th className="tabel-heading w afacad"> Change (%)  </th> 
                    <th className="tabel-heading w afacad">Volume (24H) </th>
                    <th className="tabel-heading w afacad">Circulating Market Cap</th> 
                    <th className="tabel-heading w afacad">On-Chain Market Cap</th>
                    <th className="tabel-heading w afacad">Holders</th> 
            </tr>
            <tr className="table-row">
            <td colSpan="8" className="text-center">
                        <p className=" tabel-text w inter">No transactions found.</p>
                      </td>
                {/* <td className="tabel-text w inter">1</td>
                <td className="tabel-text w inter"><img className="table-img" src="./image/busdt_32.webp" alt=""/> Binance-Peg Ethereum Token</td>
                <td className="tabel-text w inter"> <span className="g"> $2,229.1606 </span><br/>7.656163 BNB</td>
                <td className="tabel-text w inter">-4.89%</td>
                <td className="tabel-text w inter">5,420,509</td>
                <td className="tabel-text w inter">$267,898,546,437.00   	</td>
                <td className="tabel-text w inter">$1,348,642,164.00   	                </td>
                <td className="tabel-text w inter">1,732,435 <br/><span className="g"> 0.011%                </span></td> */}
            </tr>
          </table>
        </div>
        </div>
        </div>
      </div>
    </>
  );
}
export default Veiwtoken;
  



