import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from './Dropdownsvg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { base_url } from '../config/Api';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import QRCode from 'qrcode.react'; // Import QRCode component
import { NavLink } from 'react-router-dom';
import dateFormat from "dateformat";
const Address = () => {
    const [data, setData] = useState(null);
    const modalRef = useRef(null);
    const [dat, setDat] = useState(null);
    const { address } = useParams();
    const [countAddress, setcountAddress] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
    const [cumulativeIndex, setCumulativeIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // State variable for controlling modal visibility
    const [qrCodeValue, setQRCodeValue] = useState(''); // State variable to store QR code value
    const [glcBalance, setGlcBalance] = useState();
    const [glcValue, setGlcValue] = useState();
    const [lastTxnSent, setLastTxnSent] = useState();
    const [firstTxnSent, setFirstTxnSent] = useState();
    const [glcPrice, setGlcPrice] = useState(null);
    const web3Instance = new Web3('https://glc-dataseed.glcscan.co.io/');
    console.log('lastTxnSent-=', lastTxnSent);
    
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_url}/api/transaction/address/${address}?page=${currentPage}&limit=${selectedItemsPerPage}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch transactions. Status: ${response.status}`);
                }

                const result = await response.json();

                if (result && Array.isArray(result.transactionByAddress)) {
                    setData(result.transactionByAddress);
                    setcountAddress(result.txnCount);
                    setDat(result.addressBalance)

                } else {
                    console.error('Invalid or missing transaction data in the API response:', result);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transaction data:', error);
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [address, currentPage, selectedItemsPerPage]);



    console.log('dat', dat);
    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await fetch('https://api.bitnetto.io/api/v3/ticker/price?symbol=GLC');
                const result = await response.json();
                console.log('result=-=-=-=-=', result);
                setGlcPrice(result.price);

                // if (result) {

                //     const glcPrice = result.price;
                //     console.log('glcPrice-=-=-',glcPrice);
                //     setGlcPrice(glcPrice);
                // } else {
                //     console.error('Error: API response indicates failure');
                // }
            } catch (error) {
                console.error('Error fetching GLC price:', error);
            }
        };

        fetchPrice();
    }, []);
    console.log('data', data);

    useEffect(() => {
        if (data && Array.isArray(data)) {

            setGlcBalance(data[0].addressBalance);


            if (data.length > 0) {
                setLastTxnSent(data[data.length - 1].to);
                setFirstTxnSent(data[0].from);
            }
        }
    }, [data]);



    useEffect(() => {
        if (glcBalance !== undefined && glcPrice !== null) {
            const totalGlcValue = parseFloat(glcBalance) * glcPrice;
            setGlcValue(`$${totalGlcValue.toFixed(2)}`);
        }
    }, [glcBalance, glcPrice]);


    useEffect(() => {
        console.log('totalGlcBalance:', glcBalance);

    }, [glcBalance]);

    console.log('data', data);



    // console.log('data',data);
    const handleItemsPerPageChange = (selectedValue) => {
        setSelectedItemsPerPage(selectedValue);
        setCurrentPage(1);
        setCumulativeIndex(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setCumulativeIndex((page - 1) * selectedItemsPerPage);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const generateQRCode = (text) => {
        setQRCodeValue(text);
        setShowModal(true);
    };

    // const pagination = () => {
    //     const totalPages = Math.ceil(countAddress / selectedItemsPerPage);
    //     const pages = [];

    //     pages.push(
    //         <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
    //         <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
    //     );

    //     for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
    //         pages.push(
    //             <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
    //                 {i}
    //             </Pagination.Item>
    //         );
    //     }

    //     pages.push(
    //         <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
    //         <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
    //     );

    //     return <Pagination>{pages}</Pagination>;
    // };

    const pagination = () => {
        const totalPages = Math.ceil(countAddress / selectedItemsPerPage);
        const pages = [];

        pages.push(
            <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
            <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        );

        for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
            pages.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        pages.push(
            <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />,
            <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        );

        return <Pagination>{pages}</Pagination>;
    };


    const copyText = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.dismiss()
        toast.success("Text copied to clipboard!");
    };



    return (
        <>

            <>

                <div className={`modal ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: 'black', color: '#D0F459' }}>
                                <h5 className="modal-title">QR Code</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', color: '#D0F459' }}>
                                <QRCode value={qrCodeValue} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section1 my-5">
                    <div className="container">
                        <div className="row section1-box">
                            <div className="col-lg-12 col-md-12">
                                <h4 className='chart-title w afacad mb-3'> Address :- {address}
                                    <button className="copy-btn" onClick={() => copyText(address)}>
                                        <i className="fa-regular fa-copy g"></i>
                                    </button>
                                    <i className="fa-solid fa-qrcode fa-sm" style={{ color: "#D0F459", paddingTop: "10px" }} onClick={() => generateQRCode(address)}></i>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="section1 my-5">
                    <div class="container">
                        <div class="row section1-box">
                            <div class="col-lg-3 col-md-3 col_1">
                                <h4 class="chart-title w afacad mb-3 chart-title02">Overview</h4>
                                <div class="d-flex icon-box">
                                    <div class="icon-box-text">
                                        <p class="icon-box-title bl inter">GLC BALANCE</p>
                                        <p class="icon-box-sub1 w afacad">{glcBalance} GLC </p>

                                    </div>
                                </div>
                                {/* <div class="d-flex icon-box icon-box12 icon-box2">

                                    <div class="icon-box-text">
                                        <p class="icon-box-title bl inter">GLC VALUE</p>
                                        <p class="icon-box-sub1 w afacad">{glcValue}</p>
                                    </div>
                                </div> */}

                            </div>
                            <div class="col-lg-3 col-md-3 col_1">
                                {/* <h4 class="chart-title w afacad mb-3 chart-title03">More Info</h4> */}
                                {/* <div class="d-flex icon-box icon-box1">

                                    <div class="icon-box-text">
                                        <p class="icon-box-title bl inter">PRIVATE NAME TAGS</p>
                                        <p class="icon-box-sub1 w afacad"> <a href="#"> <span class="last_text-bg w">+ Add</span></a></p>
                                    </div>
                                </div> */}
                                <div class="d-flex icon-box icon-box12 icon-box2">

                                    <div class="icon-box-text">
                                        <p class="icon-box-title bl inter">GLC VALUE</p>
                                        <p class="icon-box-sub1 w afacad">{glcValue}</p>
                                        {/* <p class="icon-box-title bl inter">LAST TXN SENT</p>
                                        {/* <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                            <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p> 
                                        <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">{lastTxnSent}</a></span>
                                            <button class="copy-btn" onClick={() => copyText(lastTxnSent)}><i class="fa-regular fa-copy g"></i></button></p> */}
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-3 col-md-3 col_1">
                                <div class="d-flex icon-box icon-box2">

                                    <div class="icon-box-text">
                                        <p class="icon-box-title bl inter">FIRST TXN SENT</p>
                                        <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">
                                            {/* {firstTxnSent} */}
                                            <NavLink to={`/address/${firstTxnSent}`} id='op' className="bhcjb w">
                        {firstTxnSent?.slice(0, 20)}
                      </NavLink> 
                                            </a></span>
                                            <button class="copy-btns" onClick={() => copyText(firstTxnSent)}><i class="fa-regular fa-copy g"></i></button></p>
                                    </div>
                                </div>


                                <p></p>
                            </div>
                            <div class="col-lg-3 col-md-3">
                                <div class="d-flex icon-box icon-box2">

                                    <div class="icon-box-text">
                                        {/* <p class="icon-box-title bl inter">FIRST TXN SENT</p>
                                        <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">{firstTxnSent}</a></span>
                                            <button class="copy-btn" onClick={() => copyText(firstTxnSent)}><i class="fa-regular fa-copy g"></i></button></p> */}

                                        <p class="icon-box-title bl inter">LAST TXN SENT</p>
                                        {/* <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                            <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p> */}
                                        <p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">
                                        <NavLink to={`/address/${lastTxnSent}`} id='op' className="bhcjb w">
                        {lastTxnSent?.slice(0, 20)}
                      </NavLink>                                             </a></span>
                                            <button class="copy-btn" onClick={() => copyText(lastTxnSent)}><i class="fa-regular fa-copy g"></i></button></p>
                                    </div>
                                </div>


                                <p></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="section2">
                    <div class="container">
                        <div class="table-sec ">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p tab-btn_p1 afacad b active" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions" aria-selected="true">Transactions</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">Internal Transactions</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="token-tab" data-bs-toggle="tab" data-bs-target="#token" type="button" role="tab" aria-controls="token" aria-selected="false">Token Transfers (GLC)</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="nft-tab" data-bs-toggle="tab" data-bs-target="#nft" type="button" role="tab" aria-controls="nft" aria-selected="false">NFT Transfers</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="produced-tab" data-bs-toggle="tab" data-bs-target="#produced" type="button" role="tab" aria-controls="produced" aria-selected="false">Produced Blocks</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="validator-tab" data-bs-toggle="tab" data-bs-target="#validator" type="button" role="tab" aria-controls="validator" aria-selected="false">Validator Set Info</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="analytics-tab" data-bs-toggle="tab" data-bs-target="#analytics" type="button" role="tab" aria-controls="analytics" aria-selected="false">Analytics</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link tab-btn_p b afacad" id="multichain-tab" data-bs-toggle="tab" data-bs-target="#multichain" type="button" role="tab" aria-controls="multichain" aria-selected="false">Multichain Portfolio</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">\


                                <div class="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">

                                    <div className='tab'>
                                        <h4 className="chart-title w afacad mt-3 chart-title01" id='pod'>{countAddress < 10
                                            ? `Latest ${countAddress} from a total of ${countAddress} transactions`
                                            : `Latest 10 from a total of ${countAddress} transactions`}
                                        </h4>

                                        <div className="pagination-dropdown">
                                            <select value={selectedItemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                                {/* Add more options as needed */}
                                            </select>
                                        </div>
                                    </div>


                                    {/* <div className="transactions">
//                                             {loading ? (
//                                                 <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
//                                             ) : (
//                                                 <table className="my-3 found-table5">
//                                                     <tbody>
//                                                         <tr className="table-row">
//                                                             <th className="tabel-heading w afacad">#</th>
//                                                             <th className="tabel-heading w afacad">Transaction Hash</th>
//                                                             <th className="tabel-heading w afacad">Method</th>
//                                                             <th className="tabel-heading w afacad">Block</th>
//                                                             <th className="tabel-heading w afacad">Date Time (UTC)</th>
//                                                             <th className="tabel-heading w afacad">From</th>
//                                                             <th className="tabel-heading w afacad">To</th>
//                                                             <th className="tabel-heading w afacad">Value</th>
//                                                             <th className="tabel-heading w afacad">Txn Fee</th>
//                                                         </tr>
//                                                         {data?.map((transaction, index) => (
//                                                             <tr key={index} className="table-row">
//                                                                 <td className="tabel-text w inter">{cumulativeIndex + index + 1}</td>
//                                                                 <td className="tabel-text w inter">{transaction.hash?.slice(0, 10)}</td>
//                                                                 <td className="tabel-text transfer inter"><span>{transaction.methodId}</span></td>
//                                                                 <td className="tabel-text w inter">{transaction.blockNumber}</td>
//                                                                 <td className="tabel-text w inter">{transaction.updatedAt?.slice(0, 10)}</td>
//                                                                 <td className="tabel-text w inter">{transaction.from?.slice(0, 10)}</td>
//                                                                 <td className="tabel-text w inter">{transaction.to?.slice(0, 10)}</td>
//                                                                 <td className="tabel-text w inter">{transaction.value? parseFloat(web3Instance.utils.fromWei(transaction.value, 'ether')).toFixed(4) : <Skeleton width={50} />}</td>
//                                                                 <td className="tabel-text w inter"> {transaction.gasPrice ? parseFloat(web3Instance.utils.fromWei(transaction.gasPrice, 'ether')).toFixed(9) : null}</td>
//                                                             </tr>
//                                                         ))}
//                                                     </tbody>
//                                                 </table>
//                                             )}
//                                     </div> */}
                                    <div className="transactions">
                                        {loading ? (
                                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                                        ) : (
                                            data && data.length > 0 ? (
                                                <table className="my-3 found-table5">
                                                    <tbody>
                                                        <tr className="table-row">
                                                            <th className="tabel-heading w afacad">#</th>
                                                            <th className="tabel-heading w afacad">Transaction Hash</th>
                                                            <th className="tabel-heading w afacad">Method</th>
                                                            <th className="tabel-heading w afacad">Block</th>
                                                            <th className="tabel-heading w afacad">Date Time (UTC)</th>
                                                            <th className="tabel-heading w afacad">From</th>
                                                            <th className="tabel-heading w afacad">To</th>
                                                            <th className="tabel-heading w afacad">Value</th>
                                                            <th className="tabel-heading w afacad">Txn Fee</th>
                                                        </tr>
                                                        {data.map((transaction, index) => (
                                                            <tr key={index} className="table-row">
                                                                <td className="tabel-text w inter">{cumulativeIndex + index + 1}</td>
                                                                <td className="tabel-text w inter"><p className="icon-box-sub1 w afacad d-flex">
                                                                    <span id="sample" className="g">
                                                                        <NavLink to={`/tx/${transaction.hash}`} style={{ color: "white" }} className="bhcjb w">
                                                                            {transaction.hash}
                                                                        </NavLink>
                                                                    </span>
                                                                    <button
                                                                        className="copy-btn"
                                                                        onClick={() => copyText(transaction.hash)}
                                                                    >
                                                                        <i className="fa-regular fa-copy g"></i>
                                                                    </button>
                                                                </p></td>
                                                                <td className="tabel-text transfer inter"><span>{transaction.methodId}</span></td>
                                                                <td className="tabel-text w inter">{transaction.blockNumber}</td>
                                                                <td className="tabel-text w inter">
                                                                    {/* {transaction.updatedAt?.slice(0, 10)} */}
                                                                    {dateFormat(transaction.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}

                                                                </td>
                                                                <td className="tabel-text w inter">

                                                                    <p className="icon-box-sub1 w afacad d-flex">
                                                                        <span id="sample" className="g">
                                                                            <NavLink to={`/Address/${transaction.from}`} className="bhcjb w">
                                                                                {transaction.from}
                                                                            </NavLink>
                                                                        </span>
                                                                        <button
                                                                            className="copy-btn"
                                                                            onClick={() => copyText(transaction.from)}
                                                                        >
                                                                            <i className="fa-regular fa-copy g"></i>
                                                                        </button>
                                                                    </p>
                                                                </td>
                                                                <td className="tabel-text w inter">
                                                                    <p className="icon-box-sub1 w afacad d-flex">
                                                                        <span id="sample" className="g">
                                                                            <NavLink to={`/Address/${transaction.to}`} className="bhcjb w">
                                                                                {transaction.to}
                                                                            </NavLink>
                                                                        </span>
                                                                        <button
                                                                            className="copy-btn"
                                                                            onClick={() => copyText(transaction.to)}
                                                                        >
                                                                            <i className="fa-regular fa-copy g"></i>
                                                                        </button>
                                                                    </p>
                                                                </td>
                                                                <td className="tabel-text w inter">{transaction.value ? parseFloat(web3Instance.utils.fromWei(transaction.value, 'ether')).toFixed(4) : <Skeleton width={50} />}</td>
                                                                <td className="tabel-text w inter">{transaction.gasPrice ? parseFloat(web3Instance.utils.fromWei(transaction.gasPrice, 'ether')).toFixed(9) : null}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <table className="my-3 found-table5">
                                                    <tbody>
                                                        <tr className="no-entries">
                                                            <td colSpan="9">
                                                                {/* <img src="path/to/your/image.png" alt="No Entries" /> */}
                                                                <p className='text-center text-white'>There are no matching entries. Please try again later.</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        )}
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
                                    <h4 class="chart-title w afacad mt-3 chart-title01">  Latest 1 internal transactions </h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody><tr class="table-row">
                                                <th class="tabel-heading w afacad">#</th>
                                                <th class="tabel-heading w afacad">Parent Txn Hash</th>
                                                <th class="tabel-heading w afacad">Block </th>
                                                <th class="tabel-heading w afacad"> Date Time (UTC) </th>
                                                <th class="tabel-heading w afacad">From  </th>
                                                <th class="tabel-heading w afacad">To </th>
                                                <th class="tabel-heading w afacad">Value </th>
                                            </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">0xc5a....c25fd</td>
                                                    <td class="tabel-text w inter">35488013</td>
                                                    <td class="tabel-text w inter">2024-01-23 5:58:19</td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample"> 0xa2823F...65d6845F                </span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample"> Validator: Fuji                </span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter">0.00001 BNB                </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="token" role="tabpanel" aria-labelledby="token-tab">
                                    <h4 class="chart-title w afacad mt-3 chart-title01"> Latest 1 GLC Token Transfer Events        </h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody><tr class="table-row">
                                                <th class="tabel-heading w afacad">#</th>
                                                <th class="tabel-heading w afacad">Txn Hash	 </th>
                                                <th class="tabel-heading w afacad">Method </th>

                                                <th class="tabel-heading w afacad">Date Time (UTC) </th>
                                                <th class="tabel-heading w afacad">From  </th>
                                                <th class="tabel-heading w afacad">To </th>
                                                <th class="tabel-heading w afacad">Value </th>
                                                <th class="tabel-heading w afacad">Token </th>
                                            </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">0xc5a....c25fd</td>
                                                    <td class="tabel-text transfer inter"> <span> Reward </span></td>
                                                    <td class="tabel-text w inter">2024-01-23 5:58:19</td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample"> Validator: Fuji                </span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample">GLC: Validator Set</span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter">9,642</td>
                                                    <td class="tabel-text w inter"><a class="tabel-text w inter" href="#"> GLC: ! B.....ken</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nft" role="tabpanel" aria-labelledby="nft-tab">
                                    <h4 class="chart-title w afacad mt-3"> Latest 1 NFT Token Transfer      </h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody><tr class="table-row">
                                                <th class="tabel-heading w afacad">#</th>
                                                <th class="tabel-heading w afacad">Transaction Info	</th>
                                                <th class="tabel-heading w afacad">Method </th>
                                                <th class="tabel-heading w afacad">Date Time (UTC) </th>
                                                <th class="tabel-heading w afacad">From  </th>
                                                <th class="tabel-heading w afacad">To </th>
                                                <th class="tabel-heading w afacad">Type </th>
                                                <th class="tabel-heading w afacad">Item </th>
                                            </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">0xc5a....c25fd</td>
                                                    <td class="tabel-text transfer inter"> <span> Airdrop </span></td>
                                                    <td class="tabel-text w inter">2024-01-23 5:58:19</td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample"> Validator: Fuji                </span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample">GLC : Validator Set</span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text transfer inter"> <span> GLC-1155	                </span></td>
                                                    <td class="tabel-text w inter">
                                                        <div class="d-flex icon-box">
                                                            <div class="icon-box-icon icon-box-icon02">
                                                                <i class="fa-brands fa-github g"></i>
                                                            </div>
                                                            <div class="icon-box-text">
                                                                <p class="icon-box-title bl inter">NFT: # Mysterybox#1</p>
                                                                <p class="icon-box-sub1 w afacad">NFT: # Mysterybox</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="produced" role="tabpanel" aria-labelledby="produced-tab">
                                    <h4 class="chart-title w afacad mt-3">  Latest 1 blocks (From a total of 1,633,280 blocks with 181,379.79 GLC produced)</h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody>
                                                <tr class="table-row">
                                                    <th class="tabel-heading w afacad">#</th>
                                                    <th class="tabel-heading w afacad">Block</th>
                                                    <th class="tabel-heading w afacad">Date Time (UTC) </th>
                                                    <th class="tabel-heading w afacad">Transaction </th>
                                                    <th class="tabel-heading w afacad"> Difficulty </th>
                                                    <th class="tabel-heading w afacad">Gas Used		  </th>
                                                    <th class="tabel-heading w afacad">Reward </th>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">35488013</td>
                                                    <td class="tabel-text w inter">2024-01-23 5:58:19</td>
                                                    <td class="tabel-text w inter">87</td>
                                                    <td class="tabel-text w inter">0.00 TH	</td>
                                                    <td class="tabel-text w inter">8,282,297 (5.92%)                </td>
                                                    <td class="tabel-text w inter">0.027345314986211545 BNB                </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="validator" role="tabpanel" aria-labelledby="validator-tab">
                                    <h4 class="chart-title w afacad mt-3">  Latest 1 Validator Set         </h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody><tr class="table-row">
                                                <th class="tabel-heading w afacad">#</th>
                                                <th class="tabel-heading w afacad">Age</th>
                                                <th class="tabel-heading w afacad">Block </th>
                                                <th class="tabel-heading w afacad"> Fee Address	 </th>
                                                <th class="tabel-heading w afacad">BC Fee Address	</th>
                                                <th class="tabel-heading w afacad">Voting Power	  </th>
                                                <th class="tabel-heading w afacad">Jailed </th>
                                                <th class="tabel-heading w afacad">Incoming </th>
                                            </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">37 secs ago	</td>
                                                    <td class="tabel-text w inter"><a href="#" class="w inter">35488007</a></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad d-flex"> <span id="sample" class="g"><a href="#" class="bhcjb w">0x65d79d5f7b9e26780b60e568cad723207be92f441056221540af99dc181543c9</a></span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"> 1,114,820.18810474 BNB	</td>
                                                    <td class="tabel-text w inter">No</td>
                                                    <td class="tabel-text w inter">13.286333646062740366 BNB                </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                                    <h4 class="chart-title w afacad mt-3"> Latest 1 internal transactions      </h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody><tr class="table-row">
                                                <th class="tabel-heading w afacad">#</th>
                                                <th class="tabel-heading w afacad">Transaction Hash	                    </th>
                                                <th class="tabel-heading w afacad">Method </th>
                                                <th class="tabel-heading w afacad"> Block </th>
                                                <th class="tabel-heading w afacad">Date Time (UTC) </th>
                                                <th class="tabel-heading w afacad">From  </th>
                                                <th class="tabel-heading w afacad">To </th>
                                                <th class="tabel-heading w afacad">Value </th>
                                                <th class="tabel-heading w afacad">Txn Fee </th>
                                            </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter">0xc5a....c25fd</td>
                                                    <td class="tabel-text transfer inter"> <span> Deposit </span></td>
                                                    <td class="tabel-text w inter">35488013</td>
                                                    <td class="tabel-text w inter">2024-01-23 5:58:19</td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample"> Validator: Fuji                </span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter"><p class="icon-box-sub1 w afacad"><span id="sample">GLC: Validator Set</span>
                                                        <button class="copy-btn" onclick="copyText()"><i class="fa-regular fa-copy g"></i></button></p></td>
                                                    <td class="tabel-text w inter">0.02563623 BNB	</td>
                                                    <td class="tabel-text w inter">0</td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="multichain" role="tabpanel" aria-labelledby="multichain-tab">
                                    <h4 class="chart-title w afacad mt-3"> Multichain Portfolio | 1 Chains</h4>
                                    <div class="transactions">
                                        <table class="my-3 found-table5">
                                            <tbody>
                                                <tr class="table-row">
                                                    <th class="tabel-heading w afacad">#</th>
                                                    <th class="tabel-heading w afacad">Chain</th>
                                                    <th class="tabel-heading w afacad">Token </th>
                                                    <th class="tabel-heading w afacad"> Portfolio % </th>
                                                    <th class="tabel-heading w afacad">Price</th>
                                                    <th class="tabel-heading w afacad">Amount  </th>
                                                    <th class="tabel-heading w afacad">Value </th>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="tabel-text w inter">1</td>
                                                    <td class="tabel-text w inter"><a href="#" class="w inter">GLC</a></td>
                                                    <td class="tabel-text w inter">Terk (TERK)</td>
                                                    <td class="tabel-text w inter">39.23%	</td>
                                                    <td class="tabel-text w inter">$0.046323	</td>
                                                    <td class="tabel-text w inter">2,000	</td>
                                                    <td class="tabel-text w inter">$92.65</td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>


                            </div>

                            {pagination()}
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}
export default Address;