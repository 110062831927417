import React, { useState ,useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import Green from './Img/Green.png';
import black from './Img/black.png';
import { base_url } from './config/Api';

const LoginPage = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) {
      toast.dismiss();
      toast.error('Please fill in the details');
      return;
    }
    try {
      const response = await fetch(`${base_url}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.success === true) {
        localStorage.setItem('token', data.token);
        login({ email, password, username: data.user.username });
        toast.dismiss();
        toast.success(data.message);
        navigate('/');
      } else {
        toast.dismiss();
        // toast.error(data.message || 'Login failed. Please try again.');
        // setPasswordError(data.message || 'Login failed.');
        toast.error(data.message );
        setPasswordError(data.message );
      }
    } catch (error) {
      console.error('Error:', error);
      // toast.dismiss();
      // toast.error('An error occurred. Please try again.');
    }
  };
  useEffect(() => {
    if (rememberMe) {
      toast.dismiss();
      toast.info('Do not check this box on a public or shared PC');
    }
  }, [rememberMe]);

  return (
    <div className="section2 margin-top">
      <div className="container">
        <div className="table-sec table-sec1">
          <div className="row">
            <div className="col-lg-7 login-form">
              <form onSubmit={handleLogin}>
                <label className="form-label1 w afacad" htmlFor="email">
                  Email:
                </label>
                <div className="d-flex search-form mt-3">
                  <div className="user-icon">
                    <i className="fa-solid fa-envelope b" />
                  </div>
                  <input
                    className="form-control me-2 search-input inter"
                    id="email"
                    type="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <br />
                <label className="form-label1 w afacad" htmlFor="password">
                  Password:
                </label>
                <div className="d-flex search-form mt-3">
                  <div className="user-icon">
                    <i className="fa-solid fa-lock b" />
                  </div>
                  <input
                    className="form-control me-2 search-input inter"
                    id="password"
                    autoComplete="off"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError('');
                    }}
                  />
                  <div
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      cursor: 'pointer',
                      color: '#d0f459',
                    }}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash" />
                    ) : (
                      <i className="fa-solid fa-eye" />
                    )}
                  </div>
                </div>
                <br />
                {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                <div className="options d-flex">
                  <label className="form-label1 w afacad">
                  <input
                      type="checkbox"
                      name="remember"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />{' '}
                    Remember & auto Login
                    {/* <input type="checkbox" name="remember" /> Remember & auto Login */}
                  </label>
                  <NavLink to="/Forgetpassword" className="g form-label1 w afacad">
                    Forgot password?
                  </NavLink>
                </div>
                <br />
                <button className="form-submit afacad b Login" type="submit">
                  Log In
                </button>
              </form>
            </div>
            <div className="col-lg-5 login-form-col2">
              <div className="login-box">
                {/* <img className="logo-sign" src={black} alt="" /> */}
                <img className="logo-sign" src={Green} alt="" />
                <h4 className="form-title b afacad">Log In</h4>
                <p className="form-label1 b inter">
                  Don't have an account?{' '}
                  <NavLink to="/SignupPage" className="b form-label1 inter">
                    Sign Up
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
