import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Green from './Img/Green.png';
import { base_url } from './config/Api';

const SignupPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const validateUsername = () => {
    setUsernameError(
      username.length < 3 || username.length > 15
        ? 'Username must be between 3 and 15 characters'
        : ''
    );
  };

  
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email) ? 'Invalid email format ' : '');
  };

  const validatePassword = () => {
    setPasswordError(
      password.length < 8 || password.length > 15
        ? 'Password must be between 8 and 15 characters'
        : ''
    );
  };

  const validateConfirmPassword = () => {
    setConfirmPasswordError(
      password !== confirmPassword
        ? 'Password and confirm password do not match'
        : ''
    );
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    validateUsername();
    validateEmail();
    validatePassword();
    validateConfirmPassword();
    setTermsError('');

    if (!usernameError && !emailError && !passwordError && !confirmPasswordError) {
      if (!agreeToTerms || !receiveNewsletter) {
        setTermsError('Please check both checkboxes to proceed with Sign UP.');
        return;
      }

      try {
        
       
        
        const response = await fetch(`${base_url}/api/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, email, password, confirmPassword, receiveNewsletter }),
        });
        const data = await response.json();
        console.log(data.success)
        if (data.success === true) {
          console.log('Signup successful!');  
              toast.dismiss()

          toast.success(data.message);
          navigate(`/Account?email=${email}`);
        } else {
          console.error(data?.errors);
          toast.dismiss()

          
          toast.error(data?.errors);
        }
      } catch (error) {
        console.error('Error:', error);
       
      }
    }
  };

  return (
    <div className="section2 mt-5">
      <div className="container">
        <div className="table-sec table-sec1">
          <div className="row">
            <div className="col-lg-7 login-form">
              <form onSubmit={handleSignup}>
                <div className="form-group">
                  <label className="form-label1 w afacad" htmlFor="username">
                  User Name
                  </label>
                  <div className={`d-flex search-form mt-3 ${usernameError ? 'has-error' : ''}`}>
                    <div className="user-icon"><i className="fa-solid fa-user b"></i></div>
                    <input
                      className="form-control me-2 search-input inter"
                      id="username"
                      type="text"
                      autoComplete="off"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onBlur={validateUsername}
                    />
                  </div>
                  {usernameError && <p className="error-text" style={{ color: 'red' }}>{usernameError}</p>}
                </div>

                <div className="form-group">
                  <label className="form-label1 w afacad mt-3" htmlFor="email">
                    Email Address:
                  </label>
                  <div className={`d-flex search-form mt-3 ${emailError ? 'has-error' : ''}`}>
                    <div className="user-icon"><i className="fa-solid fa-user b"></i></div>
                    <input
                      className="form-control me-2 search-input inter"
                      id="email"
                      type="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    />
                  </div>
                  {emailError && <p className="error-text" style={{ color: 'red' }}>{emailError}</p>}
                </div>

                <div className="form-group">
                  <label className="form-label1 w afacad mt-3" htmlFor="password">
                    Password:
                  </label>
                  <div className={`d-flex search-form mt-3 ${passwordError ? 'has-error' : ''}`}>
                    <div className="user-icon"><i className="fa-solid fa-lock b"></i></div>

                    <input
                      className="form-control me-2 search-input inter"
                      style={{ backgroundColor: "#1c2531" }}
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                      }} />
                    <div
                      className="eye-icon "
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        cursor: 'pointer',
                        color: '#d0f459',
                      }}
                    >
                      {showPassword ? (
                        <i className="fa-solid fa-eye-slash " />
                      ) : (
                        <i className="fa-solid fa-eye" />
                      )}
                    </div>
                  </div>
                  {passwordError && <p className="error-text" style={{ color: 'red' }}>{passwordError}</p>}
                </div>

                <div className="form-group">
                  <label className="form-label1 w afacad mt-3" htmlFor="confirmPassword">
                    Confirm Password:
                  </label>
                  <div className={`d-flex search-form mt-3 ${confirmPasswordError ? 'has-error' : ''}`}>
                    <div className="user-icon"><i className="fa-solid fa-lock b"></i></div>
                    <input
                      className="form-control me-2 search-input inter"
                      id="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={validateConfirmPassword}
                    />
                    <div
                      className="eye-icon "
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        cursor: 'pointer',
                        color: '#d0f459',
                      }}
                    >
                      {showPassword ? (
                        <i className="fa-solid fa-eye-slash " />
                      ) : (
                        <i className="fa-solid fa-eye" />
                      )}
                    </div>
                  </div>
                  {confirmPasswordError && <p className="error-text" style={{ color: 'red' }} >{confirmPasswordError}</p>}
                </div>

                <div className="options mt-3">
                  <label className="form-label1 w afacad ">
                    <input
                      type="checkbox"
                      name="agreeToTerms"
                      checked={agreeToTerms}
                      onChange={() => setAgreeToTerms(!agreeToTerms)}
                    />{' '}
                    I agree to the   
                     <NavLink className='px ' to="/TermsandConditions" > Terms and Conditions.</NavLink>
                  </label>

                  <label className="form-label1 w afacad">
                    <input
                      type="checkbox"
                      name="receiveNewsletter"
                      checked={receiveNewsletter}
                      onChange={() => setReceiveNewsletter(!receiveNewsletter)}
                    />{' '}
                    I would like to receive the GLC Scan  <NavLink className='px' to='/newletter'>newsletter</NavLink> and understand that I can unsubscribe at any time.
                  </label>
                </div>

                {termsError && <p className="error-text" style={{ color: 'red' }}>{termsError}</p>}

                <button
                  className={`form-submit afacad b Login mt-3 ${(!agreeToTerms || !receiveNewsletter) ? 'message' : ''}`}
                  type="submit"
                  message={!agreeToTerms}
                >
                 Create an account
                </button>
              </form>
            </div>
            <div className="col-lg-5 login-form-col2">
              <div className="login-box">
                <img className="logo-sign" src={Green} alt="" />
                <h4 className="form-title b afacad">Sign Up</h4>
                <p className="form-label1 b inter">
                  Already have an account?{' '}
                  <NavLink to="/LoginPage" className="b form-label1 inter">
                    Sign In
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;

