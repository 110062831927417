
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { base_url } from '../config/Api';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
const Transactionhash = () => {

  const { hash } = useParams();
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()
    toast.success("Text copied to clipboard!");
  };

  useEffect(() => {
    const fetchBlockData = async () => {
      try {
        const response = await fetch(` ${base_url}/api/transaction/hash/${hash}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch block data. Status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result.transactions);
        setTransactionData(result.transactions);

        console.log(transactionData);
      } catch (error) {
        console.error('Error fetching block data:', error);
        setError(error.message);
        setLoading(false);

      }
    };

    fetchBlockData();
  }, []);

  // Render loading state if data is being fetched


  // Render error state if there's an error fetching data
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Render fetched data if available
  return (
    <>
      {transactionData && (
        <div className="section2 my-5">
          <div className="container">
            <div className="">
              <h2 className="w banner-title afacad">GLC Scan - Sponsored slots available. <span className="g"> Book your slot here!</span></h2>
              <ul className="nav nav-tabs mytab_rbc" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p tab-btn_p1 afacad b active" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions" aria-selected="true">Overview</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p b afacad" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">Internal Txns</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p b afacad" id="token-tab" data-bs-toggle="tab" data-bs-target="#token" type="button" role="tab" aria-controls="token" aria-selected="false">Logs (4)</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                  <div className="row mt-3 section1-box">
                    <div className="col-lg-4 col-md-4 col_1">
                      <div className="d-flex icon-box icon-box1">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Transaction Hash</p>
                          <p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g"><a href="#" className="bhcjb w">
                            {/* {transactionData.hash} */}
                            {transactionData.hash || loading ? transactionData.hash : <Skeleton width={50} baseColor="#202020" highlightColor="#444" />}
                          </a></span>
                            <button className="copy-btn" onClick={() => copyText(transactionData.hash)}><i className="fa-regular fa-copy g" /></button></p>
                        </div>
                      </div>
                      <div className="d-flex icon-box icon-box12 icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Status</p>
                          <p className="icon-box-sub1 w afacad"><span className="last_text-bg w"><i className="fa fa-check-circle me-1 g" /> Success</span></p>
                        </div>
                      </div>
                      {/* <div className="d-flex icon-box icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Block</p>
                          <p className="icon-box-sub1 w afacad"><a href="#" className="icon-box-sub1 w afacad me-2"></a>{transactionData.blockNumber}<span className="last_text-bg w">780 Block Confirmations</span></p>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-4 col-md-4 col_1">
                      <div className="d-flex icon-box icon-box1 stamp">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Timestamp</p>
                          <p className="icon-box-sub1 w afacad"><i className="far fa-clock small me-1 g" />  {transactionData.timeStamp}</p>
                        </div>
                      </div>
                      <div className="d-flex icon-box icon-box12 icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Value</p>
                          <p className="icon-box-sub1 w afacad">{transactionData.value} <span className="bl spn1">  ($11.84) </span></p>
                        </div>
                      </div>
                      {/* <div className="d-flex icon-box icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Transaction Fee</p>
                          <p className="icon-box-sub1 w afacad">0 GLC<span className="bl spn1">($0.00)</span></p>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="d-flex icon-box icon-box1 stamp">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Gas Price</p>
                          <p className="icon-box-sub1 w afacad"> {transactionData.gasPrice}<span className="bl spn1">(0 GLC)</span></p>
                        </div>
                      </div>
                      <div className="d-flex icon-box icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Block</p>
                          <p className="icon-box-sub1 w afacad"><a href="#" className="icon-box-sub1 w afacad me-2"></a>{transactionData.blockNumber}<span className="last_text-bg w">780 Block Confirmations</span></p>
                        </div>
                      </div>
                      {/* <div className="d-flex icon-box icon-box1 icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Gas Limit & Usage by Txn</p>
                          <p className="icon-box-sub1 w afacad">9,223,372,036<span className="bl spn1">(51,008 (0%))</span></p>
                        </div>
                      </div> */}
                      {/* <div className="d-flex icon-box icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Other Attributes</p>
                          <p className="icon-box-sub1 w afacad"> 0.0038046641 GLC<span className="bl spn1">($1.18)</span></p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row mt-3 section1-box">
                    <div className="col-lg-6 col-md-6 col_1">
                      <div className="d-flex icon-box icon-box1">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">From</p>
                           <p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g d-flex"><a href="#" className="bhcjb w">
                           <NavLink to={`/address/${transactionData.from}`} id='op' className="bhcjb w">
                        {transactionData.from?.slice(0, 20)}
                      </NavLink>
                            </a>(Validator: ssdsdsAvengers)</span>
                            <button className="copy-btn" onClick={() => copyText(transactionData.from)}><i className="fa-regular fa-copy g" /></button></p>
                        </div>
                      </div>
                      <div className="d-flex icon-box icon-box2">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Burnt Fees</p>
                          <p className="icon-box-sub1 w afacad">Call <span className="last_text-bg w mx-2">Deposit</span>Function by <a href="#" className="g">Validator: Avengers</a> on <a href="#" className="g">GLC: Validator Set</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="d-flex icon-box icon-box1 stamp">
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">To</p>
                          <p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g d-flex"><a href="#" className="bhcjb w">
                          <NavLink to={`/address/${transactionData.to}`} id='op' className="bhcjb w">
                        {transactionData.to?.slice(0, 20)}
                      </NavLink>
                            </a>(GLC: Validator Set)</span>
                            <button className="copy-btn" onClick={() => copyText(transactionData.to)}><i className="fa-regular fa-copy g" /></button></p>
                        </div>
                      </div>
                      <div className="d-flex icon-box icon-box2">
                        <div>
                        </div>
                        <div className="icon-box-text">
                          <p className="icon-box-title bl inter">Other Attributes</p>
                          <p className="icon-box-sub1 w afacad"><span className="last_text-bg w mx-2">Nonce:{transactionData.nonce}</span>

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 section1-box">
                    <h4 className="chart-title w afacad">Input Data</h4>
                    {/* <textarea className="form-control text-muted text-monospace p-3" rows={3} id="extraData" defaultValue={`Function:deposit(address valAddr)\n\nMethodID:${transactionData.methodId}\n[0]: 000000000000000000000000a6f79b60359f141df90a0c745125b131caaffd12\n`} /> */}
                    <textarea readonly disabled className="form-control text-muted text-monospace p-3" rows={5} id="extraData" defaultValue={`Function:deposit(address valAddr)\nMethodID:${transactionData.methodId}\n[0]: 000000000000000000000000a6f79b60359f141df90a0c745125b131caaffd12\n`} />
                    {/* <textarea className="form-control text-muted text-monospace p-3" rows={3} id="extraData" 
                    // {transactionData.methodId}
                    
                    /> */}
                    <div className="d-flex mt-4">
                      <div className="dropdown dropdown-menu-end dropdown_t">
                        <button type="button" className=" dropdown-toggle w" data-bs-toggle="dropdown">View Input As</button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item inter" href="#">Default View</a></li>
                          <li><a className="dropdown-item inter" href="#">UTF-8</a></li>
                          <li><a className="dropdown-item inter" href="#">Original</a></li>
                        </ul>
                      </div>
                      <div className="dropdown_t mx-2">
                        <a href="#" className="last_text-bg w"><i className="fa fa-cubes me-1" /> Decode Input Data</a>
                      </div>
                      <div className="dropdown_t">
                        <a href="#" className="last_text-bg w"><i className="far fa-filters me-1" /> Advanced Filter</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
                  <div className="section2 margin-top">
                    <div className="container">
                      <div className="table-sec ">
                        <h4 className="chart-title w afacad"> <i className="far fa-list-tree g" /> The contract call <span className="g"> Form </span> <a href="#" className="w "> {transactionData.from?.slice(0, 12)}</a><span className="g"> To </span> <a href="#" className="w "> {transactionData.to?.slice(0, 12)} </a> produced 2 Internal Transactions</h4>
                        <div className="transactions">
                          <table className="my-3 found-table5">
                            <tbody><tr className="table-row">
                              <th className="tabel-heading w afacad">S.No</th>
                              <th className="tabel-heading w afacad">Trace Address    </th>
                              <th className="tabel-heading w afacad">From</th>
                              <th className="tabel-heading w afacad"> To </th>
                              <th className="tabel-heading w afacad">Value</th>
                              <th className="tabel-heading w afacad">Gas Limit</th>
                            </tr>
                              <tr className="table-row">
                                <td className="tabel-text w inter">1</td>
                                <td className="tabel-text w inter"><i className="fas fa-check-circle text-success me-1 g" /> call_0_1</td>
                                <td className="tabel-text w inter"><p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g"><a href="#" className="bhcjb w">
                                  
                                <p className="icon-box-sub1 w afacad d-flex">
                                                                        <span id="sample" className="g">
                                                                            <NavLink to={`/Address/${transactionData.from}`} className="bhcjb w">
                                                                                {transactionData.from}
                                                                            </NavLink>
                                                                        </span>
                                                                        <button
                                                                            className="copy-btn"
                                                                            onClick={() => copyText(transactionData.from)}
                                                                        >
                                                                            <i className="fa-regular fa-copy g"></i>
                                                                        </button>
                                                                    </p>
                                  
                                  </a></span>
                                  <button className="copy-btn" onClick={() => copyText(transactionData.from)}><i className="fa-regular fa-copy g" /></button></p></td>
                                <td className="tabel-text w inter"><p className="icon-box-sub1 w afacad d-flex"> <span id="sample" className="g"><a href="#" className="bhcjb w"> <p className="icon-box-sub1 w afacad d-flex">
                                                                        <span id="sample" className="g">
                                                                            <NavLink to={`/Address/${transactionData.to}`} className="bhcjb w">
                                                                                {transactionData.to}
                                                                            </NavLink>
                                                                        </span>
                                                                        <button
                                                                            className="copy-btn"
                                                                            onClick={() => copyText(transactionData.to)}
                                                                        >
                                                                            <i className="fa-regular fa-copy g"></i>
                                                                        </button>
                                                                    </p></a></span>
                                  <button className="copy-btn" onClick={() => copyText(transactionData.to)}><i className="fa-regular fa-copy g" /></button></p></td>
                                <td className="tabel-text w inter">{transactionData.value}</td>
                                <td className="tabel-text w inter">2,300</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="token" role="tabpanel" aria-labelledby="token-tab">
                  <div className="section2 mt-3">
                    <div className="container">
                      <div className="table-sec">
                        <h4 className="chart-title w afacad">Transaction Receipt Event Logs</h4>
                        <div className='transactions  '>
                          <table className="my-3 found-table5 table-responsi">
                            <tbody><tr className="table-row">
                              <td className="tabel-text w inter">
                                <span className="rounded-pill">1</span>
                              </td>
                              <td className="tabel-text w inter">
                                <div className="icon-box-sub1 w afacad d-flex">Address   <span id="sample" className="g mx-2"><a href="#" className=" g"> GLC : System Reward</a></span>
                                  <button className="copy-btn" onClick={() => copyText()}><i className="fa-regular fa-copy g" /></button>
                                  <div>
                                    <div className="dropdown dropdown-menu-end dropdown_t">
                                      <button type="button" className="dropdown-toggle w" data-bs-toggle="dropdown" aria-expanded="true">View Input As</button>
                                      <ul className="dropdown-menu" data-popper-placement="top-end" style={{ position: 'absolute', inset: 'auto auto 0px 0px', margin: '0px', transform: 'translate(-68px, -24.4px)' }}>
                                        <li><a className="dropdown-item inter icon-box-sub1 b" href="#"><i className="fa-solid fa-filter g" /> Matches Topic0 (Advanced Filter)</a></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <p className="icon-box-sub1 w afacad d-flex">Name <span className="bl mx-2">receiveDeposit (index_topic_1 address from, uint256 amount) <a href="#" className="g"> View Source</a> </span></p>
                                <p className="icon-box-sub1 w afacad d-flex">Topics <span className="bl mx-2">0x6ecc855f9440a9282c90913bbc91619fd44f5ec0b462af28d127b116f130aa4d</span></p>
                                <p className="icon-box-sub1 w afacad d-flex"> Dec <span className="bl mx-2">amount :2377915120386654</span></p>
                                <p className="icon-box-sub1 w afacad d-flex"> Hex <span className="bl mx-2">0x000000000000000000000000000000000000000000000000000872b3820e825e</span></p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transactionhash;
