
import React, { useState, useEffect } from "react";
import  nft from './nft.svg'
import { Link, NavLink } from "react-router-dom";
import LineCharts from "../Homes/Homepart/ChartComponent";
import { base_url } from "../config/Api";
const Chart=()=> {
  const [revenueData, setRevenueData] = useState({});
  console.log("revenueData",revenueData);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(` ${base_url}}/api/transaction/chart`);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data");
  //       }
  //       const data = await response.json();
  //       if (typeof data === 'object' && data !== null) {
  //         setRevenueData(data);
  //       } else {
  //         console.error("Fetched data is not in the expected format:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  return (
    <>
    <div class="section2 margin-top">
    <div class="container">
      <div class="table-sec ">
      
     
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link tab-btn_p tab-btn_p1 afacad b active" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions" aria-selected="true">Market Data</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link tab-btn_p b afacad" id="produced-tab" data-bs-toggle="tab" data-bs-target="#produced" type="button" role="tab" aria-controls="produced" aria-selected="false">Contracts</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link tab-btn_p b afacad" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">Blockchain Data</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link tab-btn_p b afacad" id="token-tab" data-bs-toggle="tab" data-bs-target="#token" type="button" role="tab" aria-controls="token" aria-selected="false">Network Data</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link tab-btn_p b afacad" id="nft-tab" data-bs-toggle="tab" data-bs-target="#nft" type="button" role="tab" aria-controls="nft" aria-selected="false">Top Statistics</button>
        </li>
        
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
          <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
              <Link to= '/LineCharts'  state={[ "chartLabels","priceChartDataValues" ]} >
                <p class="icon-box-title w inter">GLC Daily Price (USD) Chart</p>
                <img class="graph-img" src={nft} alt=""/>
                </Link>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                  <p class="icon-box-title w inter">GLC Market Capitalization Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                </div>
              </a>
            </div>
            <div class="col-lg-2">
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
          <div class="row">
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <Link to= '/LineCharts'  state={[ "chartLabels","chartValues" ]} >
                  <p class="icon-box-title w inter" >Daily Transactions Chart</p><img class="graph-img" src={nft} alt=""/></Link>
                
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
              {/* <Link to= '/LineCharts'  state={[ "chartLabels","transactionFee" ]} > */}
                <p class="icon-box-title w inter"  >GLC Daily Token Transfer Chart</p>
                <img class="graph-img" src={nft} alt=""/>
                {/* </Link> */}
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                {/* <Link to= '/LineCharts'  state={[ "chartLabels","blockCount" ]} > */}
                  <p class="icon-box-title w inter">Unique Addresses Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                  {/* </Link> */}
                </div>
                {/* </Link> */}
              </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Average Block Size Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Average Block Time Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                <Link to= '/LineCharts'  state={[ "chartLabels","avgGasPriceValues" ]} >
                  <p class="icon-box-title w inter">Average Gas Price Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                  </Link>
                </div>
              </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Average Gas Limit Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
              <Link to= '/LineCharts'  state={[ "chartLabels","dailyGasPriceValues" ]} >
                <p class="icon-box-title w inter">Daily Gas Used Chart</p>
                <img class="graph-img" src={nft} alt=""/>
                </Link>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                <Link to= '/LineCharts'  state={[ "chartLabels","totalReward" ]} >
                  <p class="icon-box-title w inter">Block Count and Rewards Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                  </Link>
                </div>
              </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Daily Active GLC Smart Chain Address</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Daily Active GLC Address</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                <Link to= '/LineCharts'  state={[ "chartLabels","transactionFee" ]} >
                  <p class="icon-box-title w inter">Average Transaction Fee Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                  </Link>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="token" role="tabpanel" aria-labelledby="token-tab">
          <div class="row">
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Network Pending Transactions Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Network Transaction Fee Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
                <div class="chart-box">
                  <p class="icon-box-title w inter">Network Utilization Chart</p>
                  <img class="graph-img" src={nft} alt=""/>
                </div>
              </a>
            </div>
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">Node Tracker</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nft" role="tabpanel" aria-labelledby="nft-tab">
          <div class="row">
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">DEX Activity</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="produced" role="tabpanel" aria-labelledby="produced-tab">
          <div class="row">
            <div class="col-lg-4 mt-4">
              <a href="#">
              <div class="chart-box">
                <p class="icon-box-title w inter">GLC Smart Chain Daily Verified Contracts Chart</p>
                <img class="graph-img" src={nft} alt=""/>
              </div>
            </a>
            </div>
          </div>
        </div>
    </div>
  </div>
 </div>
</div>
    </>
  )
}

export default Chart