
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Hornavbar from './Hornavbar';
import { base_url } from '../config/Api';

import { toast } from 'react-toastify'; 
const Accountsettings = () => {
  
  // const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailModal, setEmailModal] = useState('');
  const [emailModalOldPassword, setEmailModalOldPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true); // 
  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
 
  const username = localStorage.getItem('username');
 
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const emailValue = e.target.value;
    setEmailModal(emailValue);
    setIsEmailValid(emailValue.trim().length > 0);
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleEmailUpdate = async (e) => {
    e.preventDefault();
    setShowEmailModal(true);
  };

  const handleEmailModalClose = () => {
    setEmailModal('');
    setEmailModalOldPassword('');
    setShowEmailModal(false);
  };

  function isValidEmail(email) {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
//   useEffect(() => {
    

//   handleEmailModalSubmit()
// }, [emailModal]);
  const handleEmailModalSubmit = async (e) => {
    e.preventDefault();

    if (!emailModal.trim() || !isValidEmail(emailModal.trim())) {
      toast.dismiss()
      toast.error('Email is empty or invalid');
      return;
    }
    
    try {
      const response = await fetch(` ${base_url}/api/account-settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({ email: emailModal, oldPassword: emailModalOldPassword }),
      });
      const data = await response.json();
      if (data.success === true) {
        // console.log('Email updated successfully');
        toast.dismiss()

        toast.success(data.message)
        setEmailModal('');
        setEmailModalOldPassword('');
        setShowEmailModal(false);
      } else {
        toast.dismiss()

        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    try {
      if (!oldPassword.trim() || !newPassword.trim() || !confirmPassword.trim()) {
        toast.dismiss()

        toast.error('Please fill in all fields');
        return; 
      }
      // if (!oldPassword.trim()) {
      //   toast.dismiss()

      //   toast.error('Please enter your old password');
      //   return;
      // }
      // if (newPassword !== confirmPassword) {
      //   toast.dismiss()

      //   toast.error("New password and confirm password don't match");
      //   return;
      // }
      // // Check if the length of the new password is between 6 and 26 characters
      // if (newPassword.length < 6 || newPassword.length > 26) {
      //   toast.dismiss()

      //   toast.error('Password must be between 6 and 26 characters');
      //   return;
      // }
      const response = await fetch(`${base_url}/api/account-settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
      });
      const data = await response.json();
      if (data.success === true) {
        console.log('Password updated successfully');
        toast.dismiss()

        toast.success(data.message)
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        toast.dismiss()

        toast.error(data.message)

      }
    } catch (error) {
      console.error(error);
    }
  };

  



  return (
    <>
      <div class="section2 margin-top">
        <div class="container">
          <div class="row sidbar-row">
            <div class="col-lg-3 sidbar-col">
              <div class="table-sec sidbar-box ">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <Hornavbar />
                </nav>
              </div>
            </div>
            <div class="col-lg-9">
              <div class="table-sec">
                <h4 class="chart-title w afacad">User Settings</h4>
                <p class="bl most inter mb-0">
                  Below are the username, email, and overview information for your account.
                </p>
                <form onSubmit={handleEmailUpdate}>
                  <div class="row form-row mt-3">
                    <div class="col-lg-4 col-md-4 col-sm-4 rbc">
                      <label class="form-label1 w afacad" for="username">
                        <i class="fa-regular fa-user g me-2"></i> Username:
                      </label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                      <span class="form-label1 w afacad">{username}</span>
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-lg-4 col-md-4 col-sm-4 rbc">
                      <label className="form-label1 w afacad" htmlFor="username">
                        <i className="fa-regular fa-envelope g me-2"></i> Your Email Address:
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      {/* <input
                        className="form-control me-2 search-input inter form-in"
                        id="username"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                      /> */}
                       <input
                        className="form-control me-2 search-input inter form-in"
              type="email"
              id="email"
              value={emailModal}
              onChange={(e) => setEmailModal(e.target.value)}
              required
            />
                    </div>
                  </div>
                  <div class="row form-row">
                    <div class="col-lg-4 rbc">
                      <label class="form-label1 w afacad" for="username">
                        <i class="fa-solid fa-box me-2 g"></i> Newsletter:
                      </label>
                    </div>
                    <div class="col-lg-8">
                      <label class="form-label1 w afacad form-in">
                        <input type="checkbox" name="remember" /> Subscribe to Newsletter
                      </label>
                    </div>
                  </div>
                  <button class="form-submit afacad b reset1 m-1" type="reset">
                    Cancel
                  </button>
                  <button
                    className="form-submit afacad b save-login mt-3"
                    type="button"
                    onClick={() => setShowEmailModal(true)}
                  >
                    Change Email
                  </button>

                </form>
              </div>
              <div class="table-sec mt-3">
              <h4 class="chart-title w afacad">Change Password</h4>
                <form onSubmit={handlePasswordUpdate}>
                  {/* Password input fields */}
                  <div className="row form-row mt-3">
                    <div className="col-lg-4 rbc">
                      <label className="form-label1 w afacad" htmlFor="username">
                        <i className="fa-solid fa-lock me-2 g"></i> Enter OLD password :
                      </label>
                    </div>
                    <div className="col-lg-8">
                      {/* <input
                        className="form-control me-2 search-input inter form-in"
                        id="username"
                        type="password"
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                      /> */}
                       <input
                          type={showOldPassword ? 'text' : 'password'}
                          className="form-control me-2 search-input inter form-in"
                          id="username"
                          value={oldPassword}
                          onChange={handleOldPasswordChange}
                          
                        />
                        {/* Add eye icon for toggling password visibility */}
                        {/* <div
                          className={`eye-icon ${showOldPassword ? 'hidden' : ''}`}
                          onClick={toggleShowOldPassword}
                        > */}
                          <div
                         className="eye-icon1 "
                         onClick={() => setShowOldPassword(!showOldPassword)}
                         style={{
                          cursor: 'pointer',
                          color: '#d0f459', // Set the color to #d0f459
                        }}
                        >
                          {showOldPassword ? (
                            <i className="fa-solid fa-eye-slash" />
                          ) : (
                            <i className="fa-solid fa-eye" />
                          )}
                        </div>


                    </div>
                  </div>
                  {/* New password */}
                  <div className="row form-row">
                    <div className="col-lg-4 rbc">
                      <label className="form-label1 w afacad" htmlFor="username">
                        <i className="fa-solid fa-lock me-2 g"></i> Enter NEW password :
                      </label>
                    </div>
                    <div className="col-lg-8">
                      {/* <input
                        className="form-control me-2 search-input inter form-in"
                        id="username"
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                      /> */}
                      <input
                        className="form-control me-2 search-input inter form-in"
                        id="username"
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                      />
                      <div
                         className="eye-icon1 "
                         onClick={() => setShowNewPassword(!showNewPassword)}
                         style={{
                          cursor: 'pointer',
                          color: '#d0f459', // Set the color to #d0f459
                        }}
                        >
                          {showNewPassword ? (
                            <i className="fa-solid fa-eye-slash" />
                          ) : (
                            <i className="fa-solid fa-eye" />
                          )}
                        </div>

                    </div>
                  </div>
                  {/* Confirm password */}
                  <div className="row form-row">
                    <div className="col-lg-4 rbc">
                      <label className="form-label1 w afacad" htmlFor="username">
                        <i className="fa-solid fa-lock me-2 g"></i> Re-Confirm password :
                      </label>
                    </div>
                    <div className="col-lg-8">
                      
                      <input
                        className="form-control me-2 search-input inter form-in"
                        id="username"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <div
                         className="eye-icon1 "
                         onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                         style={{
                          cursor: 'pointer',
                          color: '#d0f459', // Set the color to #d0f459
                        }}
                        >
                          {showConfirmPassword ? (
                            <i className="fa-solid fa-eye-slash" />
                          ) : (
                            <i className="fa-solid fa-eye" />
                          )}
                        </div>

                    </div>
                  </div>

                  <button class="form-submit afacad b reset1 m-1" type="reset">Cancel</button>
                  <button className="form-submit afacad b save-login mt-3" type="submit">
                  Save Changes
                  </button>
                </form>

              </div>
              <div class="table-sec mt-3">
                <h4 class="chart-title w afacad">Delete Account</h4>

                <p class="bl most inter"> <b> Are you sure you want to permanently delete your user account?</b></p>
                <p class="w most inter mb-0">Deleting your user account will also delete all the watchlists, transaction notes, private tags and verified addresses ownership. Recovery of the above is not possible upon delete confirmation.</p>
                <form>
                  <label class="form-label1 w afacad form-in">
                    <input type="checkbox" name="remember" /> Subscribe to Newsletter
                  </label><br />
                  <button class="form-submit afacad b save-login mt-3" type="submit">Delete Account</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      
<div className="section2 mt-5">
  <div className="container">
    <div className="">
      <Modal show={showEmailModal} onHide={handleEmailModalClose}>
        <Modal.Header closeButton style={{backgroundColor:"black", color:"#D0F459"}}>
          <Modal.Title >Change Email</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#10212a", color:"white"}}>
          <form onSubmit={handleEmailModalSubmit}>
            <i className="fa-solid fa-lock me-2 g"></i>
            <label htmlFor="oldPassword" className='p-2'>Old Password:- </label>
               <input
              type="text"
              id="oldPassword"
         
              value={emailModalOldPassword}
              onChange={(e) => setEmailModalOldPassword(e.target.value)}
              required
            /><br/>
            <button type="submit" className="form-submit afacad b save-login mt-3">Save Changes</button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  </div>
</div>


    </>
  );
};

export default Accountsettings;