import React from 'react'

import blockchag from './img/blockchag.png'
import gl_logoa from '../Img/gl_logoa.png'
function Apiplan() {
  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7 align-self">
              <h4 className="chart-title w inter">GLCSCAN API</h4>
              <h2 className="w banner-title afacad mb-2">Build Precise &amp; Reliable Apps with <span className="g">RBCSCAN</span></h2>
              <p className="baner-text w inter">Data from the primary Binance Smart Chain Block Explorer tailored to your requirements.</p>
              <a href="#" className="api-btn afacad b">API Pricing Plans</a>
              <a href="#" className="api-btn2 afacad w ms-2">API Documentation</a>
            </div>
            <div className="col-lg-6 col-md-5 right align-self">
              <img className="api-hero" src={blockchag} alt="api-hero-light" />
            </div>
          </div>
          <h2 className="w banner-title afacad mb-2  mt-4">Ready to get started?</h2>
          <p className="baner-text w inter fg ">Select the plan that best suits you or contact us for a custom option.</p>
          <div className="section2 section">
            <div className="container tab_api">
              <ul className="nav nav-tabs mytab_rbc1" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p tab-btn_p1 afacad b active" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions" aria-selected="true">Monthly</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p b afacad" id="internal-tab" data-bs-toggle="tab" data-bs-target="#internal" type="button" role="tab" aria-controls="internal" aria-selected="false">
                    Quarterly (10% Off)</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link tab-btn_p b afacad" id="token-tab" data-bs-toggle="tab" data-bs-target="#token" type="button" role="tab" aria-controls="token" aria-selected="false">Yearly (20% Off)</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active margin-top" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                  <div className="row api-card_row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_1">
                        <p className="api-card-sub-title w inter"> FREE</p>
                        <h4 className="g api-card-title afacad">$0 <span className="bl spn1">($11.84)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 5 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 100,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Community support</li>
                          <li className="api_list_item b inter">.</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_2">
                        <p className="api-card-sub-title w inter"> STANDARD                          </p>
                        <h4 className="g api-card-title afacad">$199 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 10 calls/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 200,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_3">
                        <p className="api-card-sub-title w inter"> ADVANCED</p>
                        <h4 className="g api-card-title afacad">$299 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 20 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 500,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_4">
                        <p className="api-card-sub-title w inter"> PROFESSIONAL</p>
                        <h4 className="g api-card-title afacad">$399 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 30 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 1000,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade  margin-top" id="internal" role="tabpanel" aria-labelledby="internal-tab">
                  <div className="row api-card_row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_1">
                        <p className="api-card-sub-title w inter"> FREE</p>
                        <h4 className="g api-card-title afacad">$0 <span className="bl spn1">($11.84)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 5 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 100,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Community support</li>
                          <li className="api_list_item b inter">.</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_2">
                        <p className="api-card-sub-title w inter"> STANDARD                          </p>
                        <h4 className="g api-card-title afacad">$179.10 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 10 calls/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 200,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_3">
                        <p className="api-card-sub-title w inter"> ADVANCED</p>
                        <h4 className="g api-card-title afacad">$239.20 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 20 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 500,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_4">
                        <p className="api-card-sub-title w inter"> PROFESSIONAL</p>
                        <h4 className="g api-card-title afacad">$319.20 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 30 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 1000,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade  margin-top" id="token" role="tabpanel" aria-labelledby="token-tab">
                  <div className="row api-card_row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_1">
                        <p className="api-card-sub-title w inter"> FREE</p>
                        <h4 className="g api-card-title afacad">$0 <span className="bl spn1">($11.84)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 5 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 100,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Community support</li>
                          <li className="api_list_item b inter">.</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_2">
                        <p className="api-card-sub-title w inter"> STANDARD                          </p>
                        <h4 className="g api-card-title afacad">$179.10 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 10 calls/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 200,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_3">
                        <p className="api-card-sub-title w inter"> ADVANCED</p>
                        <h4 className="g api-card-title afacad">$269.10 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list api-card_3">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 20 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 500,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="api-card api-card_4">
                        <p className="api-card-sub-title w inter"> PROFESSIONAL</p>
                        <h4 className="g api-card-title afacad">$359.10 <span className="bl spn1">(mo)</span></h4> <hr className="w" />
                        <ul className="api-list">
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> 30 call/second limit</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Up to 1000,000 API calls/day</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> All existing community endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Access to API Pro endpoints</li>
                          <li className="api_list_item w inter"><i className="fa-regular fa-square-check me-1 g" /> Escalated support</li>
                        </ul>
                        <img className="rbc_coin" src={gl_logoa} alt="" />
                        <a href="#" className="view-btn b afacad">Get Started Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section2 enterprise ">
        <div className="container">
          <div className="table-sec ">
            <div className="row">
              <div className="col-lg-6 Verify-col">
                <h2 className="w faq-title afacad mb-2">Frequently Asked Questions</h2>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How do I sign up for GLCScan API Services?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">Please go to the API self-checkout section above,
                          choose the plan that suits you best, and follow the
                          instructions.
                          .</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How do I upgrade or cancel my account?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">If you want to upgrade or cancel your account, please contact
                          us. We will help you.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      What payment options are there?

                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">We take major credit cards through Crypto
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading4">
                      <button className="accordion-button collapsed faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      What's your policy on refunds?
                      </button>
                    </h2>
                    <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">Payments are non-refundable, and we don't give refunds or 
credits for services already paid for.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button className="accordion-button collapsed faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                      When does the account get activated
                      </button>
                    </h2>
                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">API Pro accounts will be activated within 24 hours after we receive your username and email.
</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button className="accordion-button collapsed faq-Q w afacad" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                      How does renewal work for API Pro accounts?
                      </button>
                    </h2>
                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="faq-text baner-text w inter">How does renewal work for API Pro accounts.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 Verify-col2">
                <h2 className="w faq-title afacad mb-2">Contact Us</h2>
                <form>
                  <label className="form-label1 w afacad">Contact Name </label><br />
                  <input placeholder type="text" className="form-control1" /><br />
                  <label className="form-label1 w afacad">Company Email Address * </label><br />
                  <input placeholder type="email" className="form-control1" /><br />
                  <label className="form-label1 w afacad">Message</label><br />
                  <textarea className="form-control1" defaultValue={""} /><br />
                  <input defaultValue="Submit" type="submit" className="form-submit afacad b" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Apiplan
