import React from "react";
import Tabletransactions from './Totaltranction/Tabletransactions'
import Totaltransactions from './Totaltranction/Totaltransactions'
const Transactions = () => {
    return (
        <>
            <Totaltransactions />
            <Tabletransactions />

        </>
    )
}
export default Transactions;