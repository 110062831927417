import React, { useState, useEffect } from "react";
import Pagination from 'react-bootstrap/Pagination';
import { NavLink } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { base_url } from "../config/Api";
import {  toast } from 'react-toastify';
import Web3 from 'web3'; 
import 'react-toastify/dist/ReactToastify.css';
const Tokentransfer = () => {
  const [transaction, setTransaction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state
  const web3Instance = new Web3('http://localhost:8545'); // Replace with your Ethereum node URL if needed
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const apiUrl = `${base_url}/api/tokentxns`; // Your API endpoint
        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTransaction(data.contractTranasaction);
        setLoading(false); // Set loading to false after data fetch
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchTransactions();
  }, []);

  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()
    toast.success("Text copied to clipboard!");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (selectedValue) => {
    setSelectedItemsPerPage(selectedValue);
    setCurrentPage(1);
  };

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transaction.slice(indexOfFirstTransaction, indexOfLastTransaction);

  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="table-sec">
            <div className='tab'>
              <h4 className="chart-title w afacad">
                More Than { transaction.length} Transactions Found
              </h4>
              <select className="ms-4" value={selectedItemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="transactions">
              <table className="my-3 found-table5">
                {/* Table headers */}
                <thead>
                  <tr className="table-row">
                    <th className="tabel-heading w afacad">Sr no</th>
                    <th className="tabel-heading w afacad">Txn Hash</th>
                    <th className="tabel-heading w afacad">Method</th>
                    <th className="tabel-heading w afacad"> Age </th>
                    <th className="tabel-heading w afacad">From</th>
                    <th className="tabel-heading w afacad">To</th>
                    <th className="tabel-heading w afacad">Value</th>
                    <th className="tabel-heading w afacad">Token</th>
                    {/* Add other headers */}
                  </tr>
                </thead>
                {/* Table body */}
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8">
                        <Skeleton count={2} height={40} baseColor="#202020" highlightColor="#444"/>
                      </td>
                    </tr>
                  ) : currentTransactions.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <p className=" tabel-text w inter">No transactions found.</p>
                      </td>
                    </tr>
                  ) : (
                    currentTransactions.map((transaction, index) => (
                      <tr className="table-row" key={index}>
                        <td className="tabel-text w inter">{index + 1}</td>
                        <td className="tabel-text w inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/tx/${transaction.hash}`} style={{ color: "white" }} className="bhcjb w">
                                {transaction.hash?.slice(0, 8)}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(transaction.hash)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        <td className="tabel-text w inter">{"transfer"}</td>
                        <td className="tabel-text w inter">
                          {transaction.updatedElapsedTime}
                        </td>
                        <td className="tabel-text w inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/address/${transaction.from}`} style={{ color: "white" }} className="bhcjb w">
                                {transaction.from?.slice(0, 8)}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(transaction.from)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        <td className="tabel-text inter">
                          <p className="icon-box-sub1 w afacad d-flex">
                            <span id="sample" className="g">
                              <NavLink to={`/address/${transaction.to}`} style={{ color: "white" }} className="bhcjb w">
                                {transaction.to?.slice(0, 8)}
                              </NavLink>
                            </span>
                            <button
                              className="copy-btn"
                              onClick={() => copyText(transaction.to)}
                            >
                              <i className="fa-regular fa-copy g"></i>
                            </button>
                          </p>
                        </td>
                        <td className="tabel-text w inter">{transaction.value}</td>
                        {/* Add other table cells based on your data */}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination component */}
           {/* Pagination component */}
             <Pagination>
               <Pagination.First onClick={() => handlePageChange(1)} />
               <Pagination.Prev
                 onClick={() => handlePageChange(currentPage - 1)}
                 disabled={currentPage === 1}
               />
               {Array.from({ length: Math.ceil(transaction.length / transactionsPerPage) }).map((_, index) => (
                 <Pagination.Item
                   key={index}
                   active={index + 1 === currentPage}
                   onClick={() => handlePageChange(index + 1)}
                 >
                   {index + 1}
                 </Pagination.Item>
               ))}
               <Pagination.Next
                 onClick={() => handlePageChange(currentPage + 1)}
                 disabled={currentPage === Math.ceil(transaction.length / transactionsPerPage)}
               />
               <Pagination.Last
                 onClick={() => handlePageChange(Math.ceil(transaction.length / transactionsPerPage))}
               />
             </Pagination>
             </div>
         </div>
       </div>
     </>
   );
 };

 export default Tokentransfer;