import React from "react";
import Transactions from './Transactions/Transactions'

const Blockchain =()=>{
    return(
        <>
       <Transactions/>
   
        </>
    )
}
export default Blockchain;