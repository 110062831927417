
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Hornavbar from './Hornavbar';
import { base_url } from '../config/Api';
const Profile = () => {
  // Define state variables to store fetched data and control history visibility
  const [userData, setUserData] = useState(null);
  const [showHistory, setShowHistory] = useState(false);

  // Fetch user data using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const setToken = localStorage.getItem('token');
        const response = await fetch(`${base_url}/api/user-details`, {
          // const response = await fetch(` http://192.168.1.87:3200/api/user-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': setToken,
          },
        });
        const userDataJson = await response.json();
        setUserData(userDataJson.user);
        localStorage.setItem('username', userDataJson.user.username);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  // Function to toggle history visibility
  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  // Render loading state if user data is still being fetched
  if (!userData) {
    return <div>Loading...</div>;
  }
  // Render fetched data
  return (
    <div>
      <div className="section2 margin-top">
        <div className="container">
          <div className="row sidbar-row">
            <div className="col-lg-3 sidbar-col">
              <div className="table-sec sidbar-box " >
                <nav className="navbar navbar-expand-lg navbar-light">
                 
                  <Hornavbar />
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="table-sec">
                <h4 className="chart-title w afacad">Personal Info</h4>
                <p className="bl most inter mb-0">Below are the username, email and overview information for your account.</p>
                <div className="transactions">
                  <table className="my-3 found-table5">
                    <tbody>
                      <tr className="table-row">
                        <td className="tabel-text w inter"><i className="fa-regular fa-user g me-2"></i>Your username</td>
                        <td className="tabel-text w inter">{userData.username}</td>
                        <td className="tabel-text transfer inter"></td>
                      </tr>
                      <tr className="table-row">
                        <td className="tabel-text w inter"><i className="fa-regular fa-envelope g me-2"></i>Your Email Address:</td>
                        <td className="tabel-text w inter">{userData.email}</td>
                        <td className="tabel-text transfer inter"><NavLink to="/Accountsettings" ><span><i className="fa-solid fa-pen me-1"></i>Edit</span></NavLink></td>
                      </tr>
                      <tr className="table-row">
                        <td className="tabel-text w inter"><i className="fa-solid fa-arrow-right-from-bracket g me-2"></i>Last Login:</td>
                        <td className="tabel-text w inter">{userData.lastLogin[0]}</td>
                        <td className="tabel-text transfer inter">
                          <span onClick={toggleHistory}><i className="fa-regular fa-eye me-1"></i>History</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Display login history when showHistory is true */}
              {showHistory && (
                <div className="table-sec mt-3">
                  <h4 className="chart-title w afacad">Login History</h4>
                  <p className="bl most inter mb-0">Login history details...</p>
                  {/* Add your login history table or any other content here */}
                  <table>
                    <tbody style={{ color: "white" }}>
                      {userData.lastLogin.map((login, index) => (
                        <tr key={index}>
                          <td >{login}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="table-sec mt-3">
                <h4 className="chart-title w afacad">Overview Usage</h4>
                <p className="bl most inter mb-0">Usage of account features such as address watch list, address name tags, and API keys.</p>
                <div className="transactions">
                  <table className="my-3 found-table5">
                    <tbody>
                      <tr className="table-row">
                        <td className="tabel-text w inter"><i className="fa-solid fa-wallet g me-2"></i> Total GLC Balance (Watch List): </td>
                        <td className="tabel-text w inter">total GLC Balance</td>
                        <td className="tabel-text transfer inter"> </td>
                      </tr>
                      <tr className="table-row">
                        <td className="tabel-text w inter"><i className="fa-regular fa-envelope g me-2"></i>   Email Notification Limit: </td>
                        <td className="tabel-text w inter">emailNotificationLimit</td>
                       
                        <td className="tabel-text transfer inter"> <span>emailDailyLimit</span></td>

                      </tr>
                      <tr class="table-row">
                        <td class="tabel-text w inter"><i class="fa-regular fa-heart g me-2"></i> Address Watch List:                      </td>
                        <td class="tabel-text w inter">0 address alert(s)</td>
                        <td class="tabel-text transfer inter"> <span>50 limit                      </span></td>
                      </tr>
                      <tr class="table-row">
                        <td class="tabel-text w inter"><i class="fa-regular fa-file g me-2"></i> Txn Private Notes                      </td>
                        <td class="tabel-text w inter">0 transaction private note(s)</td>
                        <td class="tabel-text transfer inter"> <span>10,000 limit                      </span></td>
                      </tr>
                      <tr class="table-row">
                        <td class="tabel-text w inter"><i class="fa-regular fa-note-sticky g me-2"></i> Address Tags:                      </td>
                        <td class="tabel-text w inter">0 address tag(s)</td>
                        <td class="tabel-text transfer inter"> <span>5,000 limit                      </span></td>
                      </tr>
                      <tr class="table-row">
                        <td class="tabel-text w inter"><i class="fa-solid fa-database g me-2"></i> API Key Usage:                      </td>
                        <td class="tabel-text w inter">0 active API(s)</td>
                        <td class="tabel-text transfer inter"> <span>3 limit</span></td>
                      </tr>
                      <tr class="table-row">
                        <td class="tabel-text w inter"><i class="fa-regular fa-square-check g me-2"></i>  Verified Addresses :                      </td>
                        <td class="tabel-text w inter">1 verified addresses</td>
                        <td class="tabel-text transfer inter"> <span>Unlimited</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
