import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { base_url } from '../config/Api'
import { toast } from 'react-toastify'; 
import Web3 from 'web3';


const Topaccount = () => {
  const [holders, setHolders] = useState([]);
  const [countHolders, setCountHolders] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [cumulativeIndex, setCumulativeIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("balance");
  const [loading, setLoading] = useState(true);

  const web3Instance = new Web3('https://glc-dataseed.glcscan.co.io/'); // Replace with your Ethereum node URL if needed

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${base_url}/api/accounts?page=${currentPage}&limit=${parseInt(selectedItemsPerPage, 10)}`);
        setHolders(response.data.holders);
        setCountHolders(response.data.accountHolder);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedItemsPerPage]);

  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()
    toast.success("Text copied to clipboard!");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCumulativeIndex((page - 1) * selectedItemsPerPage);
  };

  const handleItemsPerPageChange = (selectedValue) => {
    setSelectedItemsPerPage(selectedValue);
    setCurrentPage(1);
    setCumulativeIndex(0);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // const pagination = () => {
  //   const totalPages = Math.ceil(countHolders / selectedItemsPerPage);
  //   const pages = [];

  //   pages.push(
  //     <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
  //     <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
  //   );

  //   for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
  //     pages.push(
  //       <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }

  //   pages.push(
  //     <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
  //     <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
  //   );

  //   return <Pagination>{pages}</Pagination>;
  // };
  

  const pagination = () => {
    const totalPages = Math.ceil(countHolders / selectedItemsPerPage);
    const pages = [];

    pages.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
        pages.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }

    pages.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />,
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return <Pagination>{pages}</Pagination>;
};

  const renderTableRows = () => {
    const sortedHolders = [...holders].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn] - b[sortColumn];
      } else {
        return b[sortColumn] - a[sortColumn];
      }
    });

    return (
      Array.isArray(sortedHolders) &&
      sortedHolders.map((holder, index) => (
        <tr className="table-row" key={index}>
          <td className="tabel-text w inter">{cumulativeIndex + index + 1}</td>
          <td className="tabel-text w inter">
            <p className="icon-box-sub1 w afacad d-flex">
              <span id="sample" className="g">
                <NavLink to ={`/Address/${holder.address}`} className="bhcjb w">
                  {holder.address}
                </NavLink>
              </span>
              <button
                className="copy-btn"
                onClick={() => copyText(holder.address)}
              >
                <i className="fa-regular fa-copy g"></i>
              </button>
            </p>
          </td>
          <td className="tabel-text w inter">{holder.tokenName}</td>
          <td className="tabel-text w inter">
            { parseFloat(web3Instance.utils.fromWei(holder.balance, 'ether')).toFixed(4)} 
            <button className="sort-btn" onClick={() => handleSort("balance")}>
              {sortColumn === "balance" && <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`}></i>}
            </button>
          </td>
          <td className="tabel-text w inter">{holder.transactionPercentage}</td>
          <td className="tabel-text w inter">{holder.totalTransactions}</td>
        </tr>
      ))
    );
  };

  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="table-sec">
            <div className='tab'>
            <h4 className="chart-title w afacad" >{`Total Holders Found ${countHolders} `}</h4>
              
              <div className="dropdown">
                <select className="mx-4" value={String(selectedItemsPerPage)} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                 
                </select>
              </div>
            </div>
            <div className="transactions">
              {loading ? (
                <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444"/>
              ) : (
                <table className="my-3 found-table5">
                  <thead>
                    <tr className="table-row ">
                      <th className="tabel-heading w afacad">Sr no</th>
                      <th className="tabel-heading w afacad">Address</th>
                      <th className="tabel-heading w afacad">Name Tag</th>
                      <th className="tabel-heading w afacad" onClick={() => handleSort("balance")}>
                        Balance {sortColumn === "balance" && <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`}></i>}
                      </th>
                      <th className="tabel-heading w afacad">Percentage</th>
                      <th className="tabel-heading w afacad">Txn Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows()}
                  </tbody>
                </table>
              )}
            </div>
            {pagination()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Topaccount;
