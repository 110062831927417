import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { base_url } from '../config/Api';

const Hornavbar = ({username, email }) => {
  const [userData, setUserData] = useState(null);
console.log('email',email);
  useEffect(() => {
    // Define a function to fetch data
    const fetchData = async () => {
      try {
        // Fetch user data
        let setToken = localStorage.getItem('token');
        const response = await fetch(`${base_url}/api/user-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: setToken,
          },
        });
        const userDataJson = await response.json();
        // Extract email and username from userDataJson and set them in state
        const { email, username } = userDataJson.user;
        setUserData({ email, username });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts
    fetchData();
  }, []);

  return (
    
      <div className="container px-0 d-block">
        <div className="d-flex pro-head">
          <div>
            {/* Display username and email if available */}
            {userData && (
              <>
                <h4 className="chart-title w afacad">{userData.username}</h4>
                <p className="bl most inter">{userData.email}</p>
              </>
            )}
          </div>
          <div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent1"
              aria-controls="navbarSupportedContent1"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
        <hr className="w hr-line" />
        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
          <ul className="navbar-nav d-block me-auto mb-2 mb-lg-0">
            <h4 className="chart-title w afacad">ACCOUNT</h4>
            {/* <li className="nav-item">
              <NavLink className="nav-link  w inter" aria-current="page" to="/Profile">
                Account Overview
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link w inter" aria-current="page"  to="/Profile">
                <div className="nav-link w inter">Account Overview</div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link w inter" aria-current="page"  to="/Accountsettings">
                <div className="nav-link w inter">Account Settings</div>
              </NavLink>
            </li>
            <h4 className="chart-title w afacad">LISTS</h4>
            <li className="nav-item">
              <NavLink className="nav-link w inter" aria-current="page"  to="/Listwatch">
                <div className="nav-link w inter">Watch List</div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link w inter" aria-current="page"  to="/Tokenignorelist">
                <div className="nav-link w inter">Token Ignore List</div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
  
  );
};

export default Hornavbar;




// import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
// import { base_url } from '../config/Api';

// const Hornavbar = () => {
//   const [userData, setUserData] = useState(null);
// // console.log('email',email);
//   useEffect(() => {
//     // Define a function to fetch data
//     // const fetchData = async () => {
//     //   try {
//     //     // Fetch user data
//     //     let setToken = localStorage.getItem('token');
//     //     const response = await fetch(`${base_url}/api/user-details`, {
//     //       method: 'GET',
//     //       headers: {
//     //         'Content-Type': 'application/json',
//     //         Authorization: setToken,
//     //       },
//     //     });
//     //     const userDataJson = await response.json();
//     //     // Extract email and username from userDataJson and set them in state
//     //     const { email, username } = userDataJson.user;
//     //     setUserData({ email, username });
//     //   } catch (error) {
//     //     console.error('Error fetching data:', error);
//     //   }
//     // };

//     // Call fetchData function when component mounts
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       // Fetch user data
//       let setToken = localStorage.getItem('token');
//       const response = await fetch(`${base_url}/api/user-details`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: setToken,
//         },
//       });
//       const userDataJson = await response.json();
//       // Extract email and username from userDataJson and set them in state
//       const { email, username } = userDataJson.user;
//       setUserData({ email, username });
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
//   return (
    
//       <div className="container px-0 d-block">
//         <div className="d-flex pro-head">
//           <div>
//             {/* Display username and email if available */}
//             {userData && (
//               <>
//                 <h4 className="chart-title w afacad">{userData.username}</h4>
//                 <p className="bl most inter">{userData.email}</p>
//               </>
//             )}
//           </div>
//           <div>
//             <button
//               className="navbar-toggler"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#navbarSupportedContent1"
//               aria-controls="navbarSupportedContent1"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button>
//           </div>
//         </div>
//         <hr className="w hr-line" />
//         <div className="collapse navbar-collapse" id="navbarSupportedContent1">
//           <ul className="navbar-nav d-block me-auto mb-2 mb-lg-0">
//             <h4 className="chart-title w afacad">ACCOUNT</h4>
//             {/* <li className="nav-item">
//               <NavLink className="nav-link  w inter" aria-current="page" to="/Profile">
//                 Account Overview
//               </NavLink>
//             </li> */}
//             <li className="nav-item">
//               <NavLink className="nav-link w inter" aria-current="page"  to="/Profile">
//                 <div className="nav-link w inter">Account Overview</div>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link w inter" aria-current="page"  to="/Accountsettings">
//                 <div className="nav-link w inter">Account Settings</div>
//               </NavLink>
//             </li>
//             <h4 className="chart-title w afacad">LISTS</h4>
//             <li className="nav-item">
//               <NavLink className="nav-link w inter" aria-current="page"  to="/Listwatch">
//                 <div className="nav-link w inter">Watch List</div>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link w inter" aria-current="page"  to="/Tokenignorelist">
//                 <div className="nav-link w inter">Token Ignore List</div>
//               </NavLink>
//             </li>
//           </ul>
//         </div>
//       </div>
  
//   );
// };

// export default Hornavbar;
// import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
// import { base_url } from '../config/Api';

// // Define fetchData function outside of the component


// const Hornavbar = () => {
//   const [userData, setUserData] = useState('');
//   const [username, setUsername] = useState('');
//   const fetchData = async (setUserData) => {
//     try {
//       // Fetch user data
//       let setToken = localStorage.getItem('token');
//       const response = await fetch(`${base_url}/api/user-details`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: setToken,
//         },
//       });
//       const userDataJson = await response.json();
//       // Extract email and username from userDataJson and set them in state
//       // const { email, username } = userDataJson.user;
//       // setTotal(response.data.records_count)
//       setUserData(userDataJson.user.email);
//       setUsername(userDataJson.user.username)
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
//   useEffect(() => {
//     // Call fetchData function when component mounts
//     fetchData(setUserData,setUsername);
//   }, [userData]);

//   return (
//     <div className="container px-0 d-block">
//       <div className="d-flex pro-head">
//         <div>
//           {/* Display username and email if available */}
//           {userData && (
//             <>
//               <h4 className="chart-title w afacad">{username}</h4>
//               <p className="bl most inter">{userData}</p>
//             </>
//           )}
//         </div>
//         <div>
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarSupportedContent1"
//             aria-controls="navbarSupportedContent1"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//         </div>
//       </div>
//       <hr className="w hr-line" />
//       <div className="collapse navbar-collapse" id="navbarSupportedContent1">
//         <ul className="navbar-nav d-block me-auto mb-2 mb-lg-0">
//           <h4 className="chart-title w afacad">ACCOUNT</h4>
//           <li className="nav-item">
//             <NavLink className="nav-link w inter" aria-current="page" to="/Profile">
//               <div className="nav-link w inter">Account Overview</div>
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink className="nav-link w inter" aria-current="page" to="/Accountsettings">
//               <div className="nav-link w inter">Account Settings</div>
//             </NavLink>
//           </li>
//           <h4 className="chart-title w afacad">LISTS</h4>
//           <li className="nav-item">
//             <NavLink className="nav-link w inter" aria-current="page" to="/Listwatch">
//               <div className="nav-link w inter">Watch List</div>
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink className="nav-link w inter" aria-current="page" to="/Tokenignorelist">
//               <div className="nav-link w inter">Token Ignore List</div>
//             </NavLink>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Hornavbar;

