// import React, { useState, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import gl_logoa from '../../Img/gl_logoa.png';
// import code from './IMG/code.png';
// import { toast } from 'react-toastify'; 
// const Banners = () => {
//   const navigate = useNavigate();
//   const [filter, setFilter] = useState('0');
//   const [searchInput, setSearchInput] = useState('');


//   const handleSearchSubmit = useCallback(
//     (e) => {
//       e.preventDefault();
//       if (filter === '0') {
//         toast.error("Please select a filter before searching.");
//         return;
//       }
//       if (filter === '1' && searchInput.trim() !== '') {
//         navigate(`/address/${searchInput}`);
//       }
//     },
//     [filter, searchInput, navigate]
//   );
  
//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <h2 className="w banner-title afacad">Green ledger Blockchain Explorer</h2>
//             <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
//               <div className="user-icon"><i className="fa-solid fa-filter" /></div>
//               <select
//                 name="f"
//                 className="form-select fs-base border-0 filterby w"
//                 value={filter}
//                 onChange={(e) => setFilter(e.target.value)}
//               >
//                 <option className="b" value="0">filter</option>
//                 <option className="b" value="1">Addresses</option>
//                 <option className="b" value="2">Tokens</option>
//                 <option className="b" value="3">Name Tags</option>
//                 <option className="b" value="4">Labels</option>
//                 <option className="b" value="5">Websites</option>
//               </select>
//               <input
//                 className="form-control me-2 search-input inter"
//                 type="search"
//                 placeholder="Search By Address"
//                 aria-label="Search"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//               />
//               <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
//             </form>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-12"></div>
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <div className="banner-box2">
//               <div className="box">
//                 <div className="row">
//                   <div className="col-6">
//                     <img src={gl_logoa} className="panda" alt="" />
//                     <p className="box-text w afacad">GLC wallet</p>
//                   </div>
//                   <div className="col-6 right">
//                     <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
//                       <img src={code} className="qr-code" alt="" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banners;

//  import React, { useState, useCallback, useEffect } from 'react';
//  import { useNavigate } from 'react-router-dom';
//  import gl_logoa from '../../Img/gl_logoa.png';
//  import code from './IMG/code.png';
//  import { toast } from 'react-toastify'; 

// const Banners = () => {
//   const navigate = useNavigate();
//   const [filter, setFilter] = useState('0');
//   const [searchInput, setSearchInput] = useState('');

//   const handleSearchSubmit = useCallback((e) => {
//     e.preventDefault();
//     if (filter === '0') {
//       toast.error("Please select a filter before searching.");
//       return;
//     }
//     if (filter === '1' && searchInput.trim() !== '') {
//       navigate(`/address/${searchInput}`);
//     }
//   }, [filter, searchInput, navigate]);

//   useEffect(() => {
//     const handleAutoSearch = () => {
//       if (searchInput.trim() !== '') {
//         // Automatically navigate based on the search input
//         navigate(`/address/${searchInput}`);
//       }
//     };

//     // Listen for changes in searchInput and trigger automatic search
//     handleAutoSearch();
//   }, [searchInput, navigate]);

//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <h2 className="w banner-title afacad">Green ledger Blockchain Explorer</h2>
//             <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
//               <div className="user-icon"><i className="fa-solid fa-filter" /></div>
//               <select
//                 name="f"
//                 className="form-select fs-base border-0 filterby w"
//                 value={filter}
//                 onChange={(e) => setFilter(e.target.value)}
//               >
//                 <option className="b" value="0">filter</option>
//                 <option className="b" value="1">Addresses</option>
//                 <option className="b" value="2">Tokens</option>
//                 <option className="b" value="3">Name Tags</option>
//                 <option className="b" value="4">Labels</option>
//                 <option className="b" value="5">Websites</option>
//               </select>
//               <input
//                 className="form-control me-2 search-input inter"
//                 type="search"
//                 placeholder="Search By Address"
//                 aria-label="Search"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//               />
//               <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
//             </form>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-12"></div>
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <div className="banner-box2">
//               <div className="box">
//                 <div className="row">
//                   <div className="col-6">
//                     <img src={gl_logoa} className="panda" alt="" />
//                     <p className="box-text w afacad">GLC wallet</p>
//                   </div>
//                   <div className="col-6 right">
//                     <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
//                       <img src={code} className="qr-code" alt="" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banners;
// import React, { useState, useCallback, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import gl_logoa from '../../Img/gl_logoa.png';
// import code from './IMG/code.png';
// import { toast } from 'react-toastify'; 

// const Banners = () => {
//   const navigate = useNavigate();
//   const [filter, setFilter] = useState('0');
//   const [searchInput, setSearchInput] = useState('');
//   const [searchClicked, setSearchClicked] = useState(false);

//   const handleSearchSubmit = useCallback((e) => {
//     e.preventDefault();
//     setSearchClicked(true);
//     if (filter === '0') {
//       toast.error("Please select a filter before searching.");
//       return;
//     }
//     if (filter === '1' && searchInput.trim() !== '') {
//       navigate(`/address/${searchInput}`);
//     }
//   }, [filter, searchInput, navigate]);

//   useEffect(() => {
//     if (searchClicked && filter !== '0' && searchInput.trim() !== '') {
//       // Automatic navigation after the search button is clicked
//       navigate(`/address/${searchInput}`);
//     }
//   }, [searchClicked, filter, searchInput, navigate]);

//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <h2 className="w banner-title afacad">Green ledger Blockchain Explorer</h2>
//             <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
//               <div className="user-icon"><i className="fa-solid fa-filter" /></div>
//               <select
//                 name="f"
//                 className="form-select fs-base border-0 filterby w"
//                 value={filter}
//                 onChange={(e) => setFilter(e.target.value)}
//               >
//                 <option className="b" value="0">filter</option>
//                 <option className="b" value="1">Addresses</option>
//                 <option className="b" value="2">Tokens</option>
//                 <option className="b" value="3">Name Tags</option>
//                 <option className="b" value="4">Labels</option>
//                 <option className="b" value="5">Websites</option>
//               </select>
//               <input
//                 className="form-control me-2 search-input inter"
//                 type="search"
//                 placeholder="Search By Address"
//                 aria-label="Search"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//               />
//               <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
//             </form>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-12"></div>
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <div className="banner-box2">
//               <div className="box">
//                 <div className="row">
//                   <div className="col-6">
//                     <img src={gl_logoa} className="panda" alt="" />
//                     <p className="box-text w afacad">GLC wallet</p>
//                   </div>
//                   <div className="col-6 right">
//                     <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
//                       <img src={code} className="qr-code" alt="" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banners;
// import React, { useState, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import gl_logoa from '../../Img/gl_logoa.png';
// import code from './IMG/code.png';
// import { toast } from 'react-toastify'; 

// const Banners = () => {
//   const navigate = useNavigate();
//   const [filter, setFilter] = useState('0');
//   const [searchInput, setSearchInput] = useState('');

//   const handleSearchSubmit = useCallback((e) => {
//     e.preventDefault();
//     if (filter === '0') {
//       toast.error("Please select a filter before searching.");
//       return;
//     }
//     if (filter === '1' && searchInput.trim() !== '') {
//       navigate(`/address/${searchInput}`);
//     }
//   }, [filter, searchInput, navigate]);

//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <h2 className="w banner-title afacad">Green ledger Blockchain Explorer</h2>
//             <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
//               <div className="user-icon"><i className="fa-solid fa-filter" /></div>
//               <select
//                 name="f"
//                 className="form-select fs-base border-0 filterby w"
//                 value={filter}
//                 onChange={(e) => setFilter(e.target.value)}
//               >
//                 <option className="b" value="0">filter</option>
//                 <option className="b" value="1">Addresses</option>
//                 <option className="b" value="2">Tokens</option>
//                 <option className="b" value="3">Name Tags</option>
//                 <option className="b" value="4">Labels</option>
//                 <option className="b" value="5">Websites</option>
//               </select>
//               <input
//                 className="form-control me-2 search-input inter"
//                 type="search"
//                 placeholder="Search By Address"
//                 aria-label="Search"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//               />
//               <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
//             </form>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-12"></div>
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <div className="banner-box2">
//               <div className="box">
//                 <div className="row">
//                   <div className="col-6">
//                     <img src={gl_logoa} className="panda" alt="" />
//                     <p className="box-text w afacad">GLC wallet</p>
//                   </div>
//                   <div className="col-6 right">
//                     <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
//                       <img src={code} className="qr-code" alt="" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banners;
// import React, { useState, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import gl_logoa from '../../Img/gl_logoa.png';
// import code from './IMG/code.png';
// import { toast } from 'react-toastify'; 

// const Banners = () => {
//   const navigate = useNavigate();
//   const [filter, setFilter] = useState('0');
//   const [searchInput, setSearchInput] = useState('');

//   const handleSearchSubmit = useCallback((e) => {
//     e.preventDefault();
//     if (searchInput.trim() === '') {
//       toast.error("Please enter a search term.");
//       return;
//     }
//     navigate(`/address/${searchInput}`);
//   }, [searchInput, navigate]);

//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <h2 className="w banner-title afacad">Green ledger Blockchain Explorer</h2>
//             <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
//               <div className="user-icon"><i className="fa-solid fa-filter" /></div>
//               <select
//                 name="f"
//                 className="form-select fs-base border-0 filterby w"
//                 value={filter}
//                 onChange={(e) => setFilter(e.target.value)}
//               >
//                 <option className="b" value="0">filter</option>
//                 <option className="b" value="1">Addresses</option>
//                 <option className="b" value="2">Tokens</option>
//                 <option className="b" value="3">Name Tags</option>
//                 <option className="b" value="4">Labels</option>
//                 <option className="b" value="5">Websites</option>
//               </select>
//               <input
//                 className="form-control me-2 search-input inter"
//                 type="search"
//                 placeholder="Search "
//                 aria-label="Search"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//               />
//               <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
//             </form>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-12"></div>
//           <div className="col-lg-5 col-md-5 col-sm-12">
//             <div className="banner-box2">
//               <div className="box">
//                 <div className="row">
//                   <div className="col-6">
//                     <img src={gl_logoa} className="panda" alt="" />
//                     <p className="box-text w afacad">GLC wallet</p>
//                   </div>
//                   <div className="col-6 right">
//                     <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
//                       <img src={code} className="qr-code" alt="" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banners;

import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import gl_logoa from '../../Img/gl_logoa.png';
import code from './IMG/code.png';
import { toast } from 'react-toastify'; 

const Banners = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('0');
  const [searchInput, setSearchInput] = useState('');
  const [placeholder, setPlaceholder] = useState('Search');

  const handleSearchSubmit = useCallback((e) => {
    e.preventDefault();
    if (searchInput.trim() === '') {
      toast.dismiss()
      toast.error("Please enter a search term.");
      return;
    }
    navigate(`/address/${searchInput}`);
  }, [searchInput, navigate]);

  // Update placeholder text based on filter selection
  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
    switch (e.target.value) {
      case '1':
        setPlaceholder('Search of Addresses');
        break;
      case '2':
        setPlaceholder('Search of Tokens');
        break;
      case '3':
        setPlaceholder('Search of  Name Tags');
        break;
      case '4':
        setPlaceholder('Search of Labels');
        break;
      case '5':
        setPlaceholder('Search of Websites');
        break;
      default:
        setPlaceholder('Search ');
        break;
    }
  }, []);

  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2 className="w banner-title afacad">Green Ledger Blockchain Explorer</h2>
            <form className="d-flex search-form mt-3" onSubmit={handleSearchSubmit}>
              <div className="user-icon"><i className="fa-solid fa-filter" /></div>
              <select
                name="f"
                className="form-select fs-base border-0 filterby w"
                value={filter}
                onChange={handleFilterChange}
              >
                <option className="b" value="0">filter</option>
                <option className="b" value="1">Addresses</option>
                <option className="b" value="2">Tokens</option>
                <option className="b" value="3">Name Tags</option>
                <option className="b" value="4">Labels</option>
                <option className="b" value="5">Websites</option>
              </select>
              <input
                className="form-control me-2 search-input inter"
                type="search"
                placeholder={placeholder}
                aria-label="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button className="search-btn" type="submit"><i className="fa-brands fa-searchengin b" /></button>
            </form>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12"></div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="banner-box2">
              <div className="box">
                <div className="row">
                  <div className="col-6">
                    <img src={gl_logoa} className="panda" alt="" />
                    <p className="box-text w afacad">GLC wallet</p>
                  </div>
                  <div className="col-6 right">
                    <a href="https://rbcasset.com/" target="_blank" rel="noreferrer">
                      <img src={code} className="qr-code" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banners;





