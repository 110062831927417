import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { base_url } from '../../config/Api';
function BlockTransaction() {
  const { blockNumber } = useParams();
  const [blockTxns, setBlockTxns] = useState([]);
  const [blockCount, setBlockCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const storedBlockNumber = localStorage.getItem('blockNumber');

  useEffect(() => {
    
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(` ${base_url}/api/blocks/blocknum/${storedBlockNumber}?transactions=true`);
        const data = await response.json();

        
        if (data && data.blockTxns && Array.isArray(data.blockTxns) && data.blockCount !== undefined) {
          setBlockTxns(data.blockTxns);
          setBlockCount(data.blockCount);
        } else {
          console.error('Invalid data format:', data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [blockNumber, storedBlockNumber]);

  return (
    <>
      <div className="section2 margin-top">
        <div className="container">
          <div className="table-sec ">
            <h4 className="chart-title w afacad">Total Of {blockCount} Blocks</h4>
            <div className="transactions">
              {loading ? (
                <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
              ) : (
                <table className="my-3 found-table1 ">
                  <tbody>
                    <tr className="table-row">
                      <th class="tabel-heading w afacad">Sr no</th>
                      <th class="tabel-heading w afacad">Txn Hash	          </th>
                      <th class="tabel-heading w afacad">Method </th>
                      <th class="tabel-heading w afacad">Block</th>
                      <th class="tabel-heading w afacad"> Age  </th>
                      <th class="tabel-heading w afacad">From  </th>
                      <th class="tabel-heading w afacad">To 	  </th>
                      <th class="tabel-heading w afacad">Value            </th>
                      <th class="tabel-heading w afacad">Txn fee</th>
                    </tr>
                    {blockTxns.map((txn, index) => (
                      <tr key={index} className="table-row">
                        
                        <td className="tabel-text w inter">{index + 1}</td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.hash}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.methodId}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.blockNumber}
                            </a>
                          </span>
                        </td>

                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.createdAt}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.from}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.to}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.value}
                            </a>
                          </span>
                        </td>
                        <td className="tabel-text w inter">
                          <span id={`sample_${index}`} className="g">
                            <a href="#" className="bhcjb w">
                              {txn.gasPrice}
                            </a>
                          </span>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockTransaction;
