import React from 'react'
import { NavLink } from 'react-router-dom'
import Hornavbar from './Hornavbar'

const Tokenignorelist=()=> {
  return (
    <div>
      <div class="section2 margin-top">
        <div class="container">
            <div class="row sidbar-row">
                <div class="col-lg-3 sidbar-col">
                    <div class="table-sec sidbar-box " >
                        <nav class="navbar navbar-expand-lg navbar-light">
                           
                            <Hornavbar/>
                          </nav>
                    </div>
                </div>
                <div class="col-lg-9">
                  <div class="table-sec" style={{height: "100%"}}>
                    <h4 class="chart-title w afacad">Custom Ignore List                    </h4>
                    <p class="bl most inter">Hide specific tokens from appearing in the Address Token Summary, Token Holdings, 
and Watch List pages. This feature is available exclusively to logged-in GLCScan users. 
Learn more here..</p>
                    <p class="most inter mb-0 g"><b>  0 token added (out of 100 max limit)                    </b></p>
                    <a class="plus-btn afacad mb-3 " href="#"><i class="fa-solid fa-plus b " ></i> Add</a>
                    <div class="transactions">
                    <table class="my-3 found-table">
                      <tbody>
                      <tr class="table-row">
                          <th class="tabel-text w inter">Token Contract Address		</th>
                          <th class="tabel-text w inter">Token		</th> 
                      </tr>
                    </tbody>
                  </table>
                </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Tokenignorelist
