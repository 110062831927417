import React from "react";

const Viewcontract = () => {
  return (
    <>
      <div className="section2 my-5">
        <div className="container">
          <div className="table-sec ">
            <div className="row">
              <div className="col-lg-6 Verify-col">
                <h4 className="chart-title w afacad">
                Verify Contract
                </h4>
                <p className="bl most inter">
                Verify & Publish Contract Source Codes Source code verification enhances transparency for 
users engaging with smart contracts. By uploading the source code, Etherscan matches it 
with the compiled code on the blockchain. This process ensures users have access to 
comprehensive information about the digital signatures they interact with and allows for 
independent code audits to confirm functionality. Please note that advanced settings, such as 
BytecodeHash or ViaIR, can be accessed through the Solidity verification method. Refer to 
Solidity's "Compiler Input and Output JSON Description" documentation for more details.
                </p>
                {/* <p className="bl most inter">
                  Please Be Informed That Advanced Settings (E.G. BytecodeHash:
                  "None" Or ViaIR: "True") Can Be Accessed Via Solidity
                  (Standard-Json-Input) Verification Method. More Information
                  Can Be Found Under Solidity's "Compiler Input And Output JSON
                  Description" Documentation Section.
                </p> */}
              </div>
              <div className="col-lg-6 Verify-col2">
                <form>
                  <label className="form-label1 w afacad">
                    Please enter the Contract Address you would like to verify
                  </label>
                  <br />
                  <input
                    placeholder="Ox..."
                    type="text"
                    className="form-control1"
                  />
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Type
                  </label>
                  <br />
                  <select
                    name="compilerType"
                    id="cars"
                    className="form-control1"
                  >
                    <option value="volvo">Open this select menu</option>
                    <option value="saab">Solidity(Single file)</option>
                    <option value="opel">Solidity (Multi-Part files)</option>
                  </select>
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Compiler Version
                  </label>
                  <br />
                  <select
                    name="compilerVersion"
                    id="cars"
                    className="form-control1"
                  >
                    <option value="volvo">Open this select menu</option>
                    <option value="saab">Solidity(Single file)</option>
                    <option value="opel">Solidity (Multi-Part files)</option>
                  </select>
                  <br />
                  <label className="form-label1 w afacad">
                    Please Select Open Source License Type
                  </label>
                  <br />
                  <select
                    name="licenseType"
                    id="cars"
                    className="form-control1"
                  >
                    <option value="volvo">Open this select menu</option>
                    <option value="saab">Solidity(Single file)</option>
                    <option value="opel">Solidity (Multi-Part files)</option>
                  </select>
                  <br />
                  <input
                    value="Continue"
                    type="submit"
                    className="form-submit afacad b"
                  />
                  <input
                    value="Reset"
                    type="reset"
                    className="form-reset afacad g"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewcontract;
