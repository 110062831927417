import React from 'react'

const Newletter = () => {
    return (
        <>
            <div className="section2 my-5">
                <div className="container">
                    <div className="table-sec ">
                        <div className="row">
                       
                            <div className="col-lg-6 Verify-col">
                            <h4 className="chart-title w afacad">Block Chain: Top Headlines
                                </h4>
                                <h4 className="chart-title g afacad">Bitcoin ETF Gets Green Light</h4>
                                <p className="bl most inter">The long-awaited Bitcoin Exchange-Traded Fund (ETF) has been approved by the SEC, opening new doors for institutional investors and boosting Bitcoin prices by 15% in the past week. This approval marks a significant milestone for the mainstream adoption of cryptocurrencies.
                                    to our guidance on pending transactions.
                                </p>
                                <h4 className="chart-title g afacad">Ethereum 2.0 Completes Latest Upgrade:    </h4>
                                <p className="bl most inter">Ethereum has successfully completed its latest network upgrade, further enhancing its scalability and security features. This upgrade is a crucial step towards transitioning from a proof-of-work to a proof-of-stake consensus mechanism.</p>
                                <h4 className="chart-title g afacad">Global Regulations Evolving
                                </h4>
                                <p className="bl most inter">The European Union has introduced a new regulatory framework for crypto-assets, aiming to provide clearer guidelines for digital currencies and blockchain technology companies. This move is expected to foster innovation while ensuring consumer protection.</p>
                                
                            </div>
                            <div className="col-lg-6 Verify-col">
                                <h4 className="chart-title w afacad">Blockchain Basics: 
                             </h4> 
                                <h4 className="chart-title g afacad">What is Blockchain?</h4>
                                <p className="bl most inter">Blockchain is a decentralized digital ledger technology that records transactions across multiple computers securely and transparently. Each block in the chain contains a list of transactions, and once added, it cannot be altered without altering subsequent blocks. This immutability makes blockchain a trusted system for various applications, including cryptocurrencies, supply chain management, and smart contracts.

                                                                                                                                            <br/><br/>
                                <h4 className="chart-title g afacad">How Does Blockchain Work?</h4>
                                <p className="bl most inter">Blockchain operates on a network of nodes (computers) that follow a consensus protocol to validate and record transactions. When a transaction is initiated, it is grouped with other transactions into a block. This block is then verified by nodes in the network through consensus mechanisms like Proof of Work (PoW) or Proof of Stake (PoS). Once verified, the block is added to the blockchain and becomes a permanent part of the ledger.</p>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newletter;
