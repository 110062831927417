import React from 'react'
import { NavLink } from 'react-router-dom'
import Hornavbar from './Hornavbar'
const  Listwatch=()=> {
  return (
    <div>
       <div class="section2 margin-top">
        <div class="container">
            <div class="row sidbar-row">
                <div class="col-lg-3 sidbar-col">
                    <div class="table-sec sidbar-box " >
                        <nav class="navbar navbar-expand-lg navbar-light">
                           
                            <Hornavbar/>
                          </nav>
                    </div>
                </div>
                <div class="col-lg-9">
                  <div class="table-sec" style={{height: "100%}"}}>
                    <h4 class="chart-title w afacad">Watch List</h4>
                    <p class="bl most inter">An Email notification can be sent to you when an address on your watch list sends or receives any transactions.</p>
                    <p class="most inter mb-0 g"><b> 0 address selected (out of 50 max limit)                    </b></p>
                    <a class="plus-btn afacad" href="#"><i class="fa-solid fa-plus b"></i> Add</a>
                    <div class="transactions">
                    <table class="my-3 found-table">
                      <tbody>
                      <tr class="table-row">
                          <th class="tabel-text w inter">Address	</th>
                          <th class="tabel-text w inter">Name Tags	</th> 
                          <th class="tabel-text w inter"> Notification</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Listwatch
